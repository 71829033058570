document.addEventListener('turbo:load', function(){
    // Downloads & Unique Listeners
    const downloadParams = ["downloads"];
    const downloadNames = ["Downloads"];
    const downloadSelectors = ["#chart-download"];
    const downloadID = ["downloadChartID"];
    // const downloadParams = ["downloads", "unique_listeners"];
    // const downloadNames = ["Downloads", "Unique Listeners"];
    // const downloadSelectors = ["#chart-download", "#chart-unique"];
    // const downloadID = ["downloadChartID", "uniqueChartID"];
    // JS for the Downloads & Unique Listeners page
    if (document.querySelector("#chart-download")) {
        let downloadOptions = {};
        let downloadChart;          
        fetch(`/analytics/charts?chart_type=downloads&date_range=7d`)
        .then(res => res.json())
        .then(data => {
            downloadOptions = {
                colors: ["#391599"],
                fill: {
                    type: "solid",
                    colors: ["#CDC1ED"],
                },
                chart: {
                    height: 380,
                    width: "100%",
                    type: "area",
                    id: "downloadChartID",
                    fontFamily: "manrope"
                },
                dataLabels: {
                    enabled: false
                },
                series: [
                {
                    name: "Downloads",
                    data: data
                }
                ],
                xaxis: {
                type: 'datetime'
                }
            };
    
            downloadChart = new ApexCharts(document.querySelector("#chart-download"), downloadOptions);
    
            downloadChart.render();
            updateTotal(data, "downloads");
        });

        // Range logic
        const rangeButtons = document.querySelectorAll(".rangeButton");
        rangeButtons.forEach(element => {
            element.addEventListener("click", function() {
                const chartID = this.dataset.chartid;
                const range = this.dataset.range;
                const chartType = this.dataset.chartType;
                fetch(`/analytics/charts?chart_type=${chartType}&date_range=${range}`)
                .then(res => res.json())
                .then(data => {
                    downloadChart.updateSeries([{data: data}], true);
                    updateTotal(data, chartType);
                })
            })
        })

        // Update 'Total' number
        function updateTotal(data, elementID) {
            let newTotal = 0
            for (let i = 0; i < data.length; i++) {
                newTotal += data[i][1]
            }
            document.querySelector(`#${elementID}-total`).textContent = newTotal;
        }

        // Button styling 
        const downloadButtons = document.querySelectorAll('[data-chart-type="downloads"]');
        const uniqueButtons = document.querySelectorAll('[data-chart-type="unique_listeners"]');
        const multipleButtons = document.querySelectorAll('.rangeButtonMultiple');
        const buttonArray = [downloadButtons, uniqueButtons, multipleButtons];
        buttonArray.forEach(btn => {
            btn.forEach(element => {
                element.addEventListener("click", function() {
                    btn.forEach(element => {
                        element.classList.remove("bg-quill-activenav")
                        element.classList.add("bg-white")
                    })
                    this.classList.remove("bg-whit")
                    this.classList.add("bg-quill-activenav")
                })
            })
        });
        
        const downloadRange = flatpickr("#download-range", {mode: "range"});
        // const uniqueRange = flatpickr("#unique-range", {mode: "range"});
        downloadRange.config.onChange.push(function(selectedDates, dateStr, instance){
            if (selectedDates.length === 2) {
                fetch(`/analytics/charts?chart_type=downloads&date_range=custom&beginning_date=${selectedDates[0]}&end_date=${selectedDates[1]}`)
                .then(res => res.json())
                .then(data => {
                    downloadChart.updateSeries([{data: data}], true);
                    updateTotal(data, "downloads");
                })
            }
        })

        // Multiple Episode stuff
        const element = document.querySelector('#multiple-select');
        const choices = new Choices(element, {
            removeItemButton: true,
            maxItemCount: 5,
            placeholderValue: "Choose up to 5 episodes to compare.",
            shouldSort: false
        });


        // Instantiate the multiple episode chart
        let multipleChartOptions = {
            colors: ["#5EBAB4", "#853ACF", "#D78846", "#1C7EBD", "#CF3A5F"],
            series: [],
            chart: {
                height: 380,
                width: "100%",
                type: "line",
                zoom: {
                    type: 'xy'
                },
                id: "multiple-ep-chart",
                fontFamily: "manrope"
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                shared: true,
                    x: {
                    format: "dd MMM yyyy"
                    },
            }
        };
        
        let multipleChart = new ApexCharts(document.querySelector("#chart-multiple"), multipleChartOptions);
        
        multipleChart.render();
        
        let multipleSeriesArr = [];
        // let multipleSeriesIDs = [];
        let multipleDateRange = "";
        let maxDayRange = "7d";
        element.addEventListener("addItem", function(event){
            // First if statement will not fire anymore since we took out custom ranges, remove it
            if (Array.isArray(multipleDateRange)) {
                fetch(`/analytics/charts?chart_type=multiple_episode_downloads&date_range=custom&beginning_date=${multipleDateRange[0]}&end_date=${multipleDateRange[1]}&episode_id=${event.detail.value}`)
                .then(res => res.json())
                .then(data => {
                    const newData = {name: data[0].name, data: data[0].data, id: data[0].id}
                    multipleSeriesArr.push(newData);
                    // multipleSeriesIDs.push(event.detail.value);
                    multipleChart.updateSeries(multipleSeriesArr, true);
                });
            } else {
                fetch(`/analytics/charts?chart_type=multiple_episode_downloads&episode_date_range=${maxDayRange}&episode_id=${event.detail.value}`)
                .then(res => res.json())
                .then(data => {
                    const newData = {name: data[0].name, data: data[0].data, id: data[0].id, total: data[0].total, episode_number: data[0].episode_number}
                    // addRowToEpisodeComparisonTable(newData)
                    multipleSeriesArr.push(newData);
                    resetAndRebuildEpisodeComparisonTable(multipleSeriesArr)
                    dayMissingCheck(multipleSeriesArr, maxDayRange)
                    // multipleSeriesIDs.push(event.detail.value);
                    multipleChart.updateSeries(multipleSeriesArr, true);
                });
            }            
        })

        function addRowToEpisodeComparisonTable(data) {
            const table = document.querySelector("#episode-comparison-table")
            table.innerHTML += `
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">${data.episode_number}</td>
              <td class="px-3 py-4 text-sm text-gray-500">${data.name}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${data.total}</td>
            </tr>
            `
        }

        function resetAndRebuildEpisodeComparisonTable(data) {
            const table = document.querySelector("#episode-comparison-table")
            const colors = ["#5EBAB4", "#853ACF", "#D78846", "#1C7EBD", "#CF3A5F"]
            table.innerHTML = ""
            for (let i = 0; i < data.length; i++) {
                table.innerHTML += `
                <tr>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"><span class="rounded-full h-5 w-5 items-center justify-center mr-3" style="background-color: ${colors[i]}; padding: 0px 10px;"></span>${data[i].episode_number}</td>
                  <td class="px-3 py-4 text-sm text-gray-500">${data[i].name}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${data[i].total}</td>
                </tr>
                `
            }

        }

        element.addEventListener("removeItem", function(event){
            for (let i = 0; i < multipleSeriesArr.length; i++) {
                const element = multipleSeriesArr[i];
                if (element.id === event.detail.value) {
                    multipleSeriesArr.splice(i, 1);
                    // multipleSeriesIDs.splice(multipleSeriesIDs.indexOf(event.detail.value), 1);
                    multipleChart.updateSeries(multipleSeriesArr, true);
                    dayMissingCheck(multipleSeriesArr, maxDayRange)
                    resetAndRebuildEpisodeComparisonTable(multipleSeriesArr)
                    break;
                }
            }
        })

        document.querySelector("#episode-comparison-range").addEventListener("change", function() {
            if (multipleSeriesArr.length > 0) {
                const value = document.querySelector("#episode-comparison-range").value
                maxDayRange = value
                idList = []
                multipleSeriesArr.forEach(function(element){
                    idList.push(element.id);
                });
                fetch(`/analytics/charts?chart_type=multiple_episode_downloads&episode_date_range=${value}&episode_id=${idList.join()}`)
                    .then(res => res.json())
                    .then(data => {
                        multipleSeriesArr = data;
                        multipleChart.updateSeries(multipleSeriesArr, true);
                        dataEmptyCheck(data);
                        dayMissingCheck(data, value)
                        resetAndRebuildEpisodeComparisonTable(multipleSeriesArr)
                    })
            }

        })

        // When you press a range button with data already loaded, the order changes when it shouldn't (backend sends it in order different from id order)
        const multipleRange = flatpickr("#multiple-range", {mode: "range"});
        multipleRange.config.onChange.push(function(selectedDates, dateStr, instance){
            if (selectedDates.length === 2 && multipleSeriesArr.length > 0) {
                multipleDateRange = [selectedDates[0], selectedDates[1]];
                idList = []
                multipleSeriesArr.forEach(function(element){
                    idList.push(element.id);
                });
                fetch(`/analytics/charts?chart_type=multiple_episode_downloads&date_range=custom&beginning_date=${selectedDates[0]}&end_date=${selectedDates[1]}&episode_id=${idList.join()}`)
                .then(res => res.json())
                .then(data => {
                    multipleSeriesArr = data;
                    multipleChart.updateSeries(multipleSeriesArr, true);
                    dataEmptyCheck(data);
                })
            }
        });
        const rangeButtonMultiple = document.querySelectorAll(".rangeButtonMultiple");
        rangeButtonMultiple.forEach(element => {
            element.addEventListener("click", function() {
                if (multipleSeriesArr.length > 0) {
                    const range = this.dataset.range;
                    multipleDateRange = range;
                    idList = []
                    multipleSeriesArr.forEach(function(element){
                        idList.push(element.id);
                    })
                    fetch(`/analytics/charts?chart_type=multiple_episode_downloads&date_range=${range}&episode_id=${idList.join()}`)
                    .then(res => res.json())
                    .then(data => {
                        multipleSeriesArr = data;
                        multipleChart.updateSeries(multipleSeriesArr, true);
                        dataEmptyCheck(data);
                    })
                }
            })
        });
        // Auto load 2 most recent episodes for comparison
        const autoLoadIDs = document.querySelector("#auto-load-ids");
        if(autoLoadIDs) {
            const loadEP1 = autoLoadIDs.dataset.ep1;
            const loadEP2 = autoLoadIDs.dataset.ep2;
            choices.setChoiceByValue([loadEP1, loadEP2]);
        }
        
        function dataEmptyCheck(data) {
            const msgElement = document.querySelector("#no-data-msg");
            let emptyNames = [];
            for(let i = 0; i < data.length; i++) {
                if (data[i].data.length === 0) {
                    emptyNames.push(data[i].name)
                }
            }
            if (emptyNames.length === 0) {
                msgElement.classList.add("hidden")
            } else if (emptyNames.length === data.length) {
                msgElement.classList.remove("hidden")
                msgElement.textContent = "Note: There is no data to display from the selected episodes in this range"
            } else if (emptyNames.length === 1) {
                msgElement.classList.remove("hidden")
                msgElement.textContent = `Note: There is no data to display from the episode ${emptyNames.join(", ")} in this range`
            } else {
                msgElement.classList.remove("hidden")
                msgElement.textContent = `Note: There is no data to display from the episodes ${emptyNames.join(", ")} in this range`
            }
        }

        function dayMissingCheck(data, days) {
            const msgElement = document.querySelector("#missing-date-msg")
            msgElement.classList.add("hidden")
            let maxDay
            if (days === "7d") {
                maxDay = 6
            } else if (days == "14d") {
                maxDay = 13
            } else if (days == "30d") {
                maxDay = 29
            } else {
                return
            }
            for (let i = 0; i < data.length; i++) {
                if (data[i].data.length < maxDay) {
                    msgElement.classList.remove("hidden")
                }
            }
        }
    }
    if (document.querySelector("#chart-unique")) {
        let uniqueOptions = {};
        let uniqueChart;          
        fetch(`/analytics/charts?chart_type=unique_listeners&date_range=7d`)
        .then(res => res.json())
        .then(data => {
            uniqueOptions = {
                colors: ["#93D2D6"],
                fill: {
                    type: "solid",
                    colors: ["#E3FDFE"],
                },
                chart: {
                    height: 380,
                    width: "100%",
                    type: "area",
                    id: "uniqueChartID",
                    fontFamily: "manrope"
                },
                dataLabels: {
                    enabled: false
                },
                series: [
                {
                    name: "Unique Listeners",
                    data: data
                }
                ],
                xaxis: {
                type: 'datetime'
                }
            };
    
            uniqueChart = new ApexCharts(document.querySelector("#chart-unique"), uniqueOptions);
    
            uniqueChart.render();
            updateTotal(data, "unique_listeners");
        });

        // Range logic
        const rangeButtons = document.querySelectorAll(".rangeButton");
        rangeButtons.forEach(element => {
            element.addEventListener("click", function() {
                const chartID = this.dataset.chartid;
                const range = this.dataset.range;
                const chartType = this.dataset.chartType;
                fetch(`/analytics/charts?chart_type=${chartType}&date_range=${range}`)
                .then(res => res.json())
                .then(data => {
                    uniqueChart.updateSeries([{data: data}], true);
                    updateTotal(data, chartType);
                })
            })
        })

        // Update 'Total' number
        function updateTotal(data, elementID) {
            let newTotal = 0
            for (let i = 0; i < data.length; i++) {
                newTotal += data[i][1]
            }
            document.querySelector(`#${elementID}-total`).textContent = newTotal;
        }

        // Button styling 
        const downloadButtons = document.querySelectorAll('[data-chart-type="downloads"]');
        const uniqueButtons = document.querySelectorAll('[data-chart-type="unique_listeners"]');
        const multipleButtons = document.querySelectorAll('.rangeButtonMultiple');
        const buttonArray = [downloadButtons, uniqueButtons, multipleButtons];
        buttonArray.forEach(btn => {
            btn.forEach(element => {
                element.addEventListener("click", function() {
                    btn.forEach(element => {
                        element.classList.remove("bg-quill-activenav")
                        element.classList.add("bg-white")
                    })
                    this.classList.remove("bg-whit")
                    this.classList.add("bg-quill-activenav")
                })
            })
        });
        
        const uniqueRange = flatpickr("#unique-range", {mode: "range"});
        uniqueRange.config.onChange.push(function(selectedDates, dateStr, instance){
            if (selectedDates.length === 2) {
                fetch(`/analytics/charts?chart_type=unique_listeners&date_range=custom&beginning_date=${selectedDates[0]}&end_date=${selectedDates[1]}`)
                .then(res => res.json())
                .then(data => {
                    uniqueChart.updateSeries([{data: data}], true);
                    updateTotal(data, "unique_listeners");
                })
            }
        });

        // Multiple Episode stuff
        const element = document.querySelector('#multiple-select');
        const choices = new Choices(element, {
            removeItemButton: true,
            maxItemCount: 5,
            placeholderValue: "Choose up to 5 episodes to compare.",
            shouldSort: false
        });


        // Instantiate the multiple episode chart
        let multipleChartOptions = {
            colors: ["#5EBAB4", "#853ACF", "#D78846", "#1C7EBD", "#CF3A5F"],
            series: [],
            chart: {
                height: 380,
                width: "100%",
                type: "line",
                zoom: {
                    type: 'xy'
                },
                id: "multiple-ep-chart",
                fontFamily: "manrope"
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                shared: true,
                    x: {
                    format: "dd MMM yyyy"
                    },
            }
        };
        
        let multipleChart = new ApexCharts(document.querySelector("#chart-multiple"), multipleChartOptions);
        
        multipleChart.render();
        
        let multipleSeriesArr = [];
        // let multipleSeriesIDs = [];
        let multipleDateRange = "";
        let maxDayRange = "7d";
        element.addEventListener("addItem", function(event){
            // First if statement will not fire anymore since we took out custom ranges, remove it
            if (Array.isArray(multipleDateRange)) {
                fetch(`/analytics/charts?chart_type=multiple_episode_unique&date_range=custom&beginning_date=${multipleDateRange[0]}&end_date=${multipleDateRange[1]}&episode_id=${event.detail.value}`)
                .then(res => res.json())
                .then(data => {
                    const newData = {name: data[0].name, data: data[0].data, id: data[0].id}
                    multipleSeriesArr.push(newData);
                    // multipleSeriesIDs.push(event.detail.value);
                    multipleChart.updateSeries(multipleSeriesArr, true);
                });
            } else {
                fetch(`/analytics/charts?chart_type=multiple_episode_unique&episode_date_range=${maxDayRange}&episode_id=${event.detail.value}`)
                .then(res => res.json())
                .then(data => {
                    const newData = {name: data[0].name, data: data[0].data, id: data[0].id, total: data[0].total, episode_number: data[0].episode_number}
                    // addRowToEpisodeComparisonTable(newData)
                    multipleSeriesArr.push(newData);
                    resetAndRebuildEpisodeComparisonTable(multipleSeriesArr)
                    dayMissingCheck(multipleSeriesArr, maxDayRange)
                    // multipleSeriesIDs.push(event.detail.value);
                    multipleChart.updateSeries(multipleSeriesArr, true);
                });
            }            
        })

        function addRowToEpisodeComparisonTable(data) {
            const table = document.querySelector("#episode-comparison-table")
            table.innerHTML += `
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">${data.episode_number}</td>
              <td class="px-3 py-4 text-sm text-gray-500">${data.name}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${data.total}</td>
            </tr>
            `
        }

        function resetAndRebuildEpisodeComparisonTable(data) {
            const table = document.querySelector("#episode-comparison-table")
            const colors = ["#5EBAB4", "#853ACF", "#D78846", "#1C7EBD", "#CF3A5F"]
            table.innerHTML = ""
            for (let i = 0; i < data.length; i++) {
                table.innerHTML += `
                <tr>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"><span class="rounded-full h-5 w-5 items-center justify-center mr-3" style="background-color: ${colors[i]}; padding: 0px 10px;"></span>${data[i].episode_number}</td>
                  <td class="px-3 py-4 text-sm text-gray-500">${data[i].name}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${data[i].total}</td>
                </tr>
                `
            }

        }

        element.addEventListener("removeItem", function(event){
            for (let i = 0; i < multipleSeriesArr.length; i++) {
                const element = multipleSeriesArr[i];
                if (element.id === event.detail.value) {
                    multipleSeriesArr.splice(i, 1);
                    // multipleSeriesIDs.splice(multipleSeriesIDs.indexOf(event.detail.value), 1);
                    multipleChart.updateSeries(multipleSeriesArr, true);
                    dayMissingCheck(multipleSeriesArr, maxDayRange)
                    resetAndRebuildEpisodeComparisonTable(multipleSeriesArr)
                    break;
                }
            }
        })

        document.querySelector("#episode-comparison-range").addEventListener("change", function() {
            if (multipleSeriesArr.length > 0) {
                const value = document.querySelector("#episode-comparison-range").value
                maxDayRange = value
                idList = []
                multipleSeriesArr.forEach(function(element){
                    idList.push(element.id);
                });
                fetch(`/analytics/charts?chart_type=multiple_episode_unique&episode_date_range=${value}&episode_id=${idList.join()}`)
                    .then(res => res.json())
                    .then(data => {
                        multipleSeriesArr = data;
                        multipleChart.updateSeries(multipleSeriesArr, true);
                        dataEmptyCheck(data);
                        dayMissingCheck(data, value)
                        resetAndRebuildEpisodeComparisonTable(multipleSeriesArr)
                    })
            }

        })

        // When you press a range button with data already loaded, the order changes when it shouldn't (backend sends it in order different from id order)
        const multipleRange = flatpickr("#multiple-range", {mode: "range"});
        multipleRange.config.onChange.push(function(selectedDates, dateStr, instance){
            if (selectedDates.length === 2 && multipleSeriesArr.length > 0) {
                multipleDateRange = [selectedDates[0], selectedDates[1]];
                idList = []
                multipleSeriesArr.forEach(function(element){
                    idList.push(element.id);
                });
                fetch(`/analytics/charts?chart_type=multiple_episode_unique&date_range=custom&beginning_date=${selectedDates[0]}&end_date=${selectedDates[1]}&episode_id=${idList.join()}`)
                .then(res => res.json())
                .then(data => {
                    multipleSeriesArr = data;
                    multipleChart.updateSeries(multipleSeriesArr, true);
                    dataEmptyCheck(data);
                })
            }
        });
        const rangeButtonMultiple = document.querySelectorAll(".rangeButtonMultiple");
        rangeButtonMultiple.forEach(element => {
            element.addEventListener("click", function() {
                if (multipleSeriesArr.length > 0) {
                    const range = this.dataset.range;
                    multipleDateRange = range;
                    idList = []
                    multipleSeriesArr.forEach(function(element){
                        idList.push(element.id);
                    })
                    fetch(`/analytics/charts?chart_type=multiple_episode_unique&date_range=${range}&episode_id=${idList.join()}`)
                    .then(res => res.json())
                    .then(data => {
                        multipleSeriesArr = data;
                        multipleChart.updateSeries(multipleSeriesArr, true);
                        dataEmptyCheck(data);
                    })
                }
            })
        });
        // Auto load 2 most recent episodes for comparison
        const autoLoadIDs = document.querySelector("#auto-load-ids");
        if(autoLoadIDs) {
            const loadEP1 = autoLoadIDs.dataset.ep1;
            const loadEP2 = autoLoadIDs.dataset.ep2;
            choices.setChoiceByValue([loadEP1, loadEP2]);
        }
        
        function dataEmptyCheck(data) {
            const msgElement = document.querySelector("#no-data-msg");
            let emptyNames = [];
            for(let i = 0; i < data.length; i++) {
                if (data[i].data.length === 0) {
                    emptyNames.push(data[i].name)
                }
            }
            if (emptyNames.length === 0) {
                msgElement.classList.add("hidden")
            } else if (emptyNames.length === data.length) {
                msgElement.classList.remove("hidden")
                msgElement.textContent = "Note: There is no data to display from the selected episodes in this range"
            } else if (emptyNames.length === 1) {
                msgElement.classList.remove("hidden")
                msgElement.textContent = `Note: There is no data to display from the episode ${emptyNames.join(", ")} in this range`
            } else {
                msgElement.classList.remove("hidden")
                msgElement.textContent = `Note: There is no data to display from the episodes ${emptyNames.join(", ")} in this range`
            }
        }

        function dayMissingCheck(data, days) {
            const msgElement = document.querySelector("#missing-date-msg")
            msgElement.classList.add("hidden")
            let maxDay
            if (days === "7d") {
                maxDay = 6
            } else if (days == "14d") {
                maxDay = 13
            } else if (days == "30d") {
                maxDay = 29
            } else {
                return
            }
            for (let i = 0; i < data.length; i++) {
                if (data[i].data.length < maxDay) {
                    msgElement.classList.remove("hidden")
                }
            }
        }
    } 
    function groupListeningMethods(groupName, tempObj, total, data, i) {
        if (tempObj.hasOwnProperty(groupName)) {
            tempObj[groupName] += data.series[i]
            total += data.series[i]
        } else {
            tempObj[groupName] = data.series[i]
            total += data.series[i]
        }
        return [tempObj, total]
    }
    // Profile Page
    const audienceParams = ["os", "listening_on_browser"];
    const audienceNames = ["Operating system", "Browsers"];
    const audienceSelectors = ["#os", "#listening-browser"];
    const audienceLegend = ["#os-legend", "#browser-legend"];
    const chartColors = ["#1C7EBD", "#5EBAB4", "#7237BE", "#E9D2F4", "#B59194"]
    let topListeningMethodsData;
    let pieChartData = [[["Operating System", "Downloads"]], [["Browsers", "Downloads"]]];

    if (document.querySelector("#listening-browser-pie")) {
        const listeningMethodsRange = flatpickr("#listening-methods-range", {mode: "range"});
        const browsersRange = flatpickr("#browsers-range", {mode: "range", maxDate: "today"});
        const browsersRangeClear = document.getElementById('browsers-range-clear');
        const osRange = flatpickr("#os-range", {mode: "range", maxDate: "today"});
        const osRangeClear = document.getElementById('os-range-clear');
        const timeRange = flatpickr("#downloads-by-time-range", {mode: "range"});
        let osChart;
        let browserChart;
        const donutCharts = [osChart, browserChart];
        let timeChart;



        // const uniqueRange = flatpickr("#unique-range", {mode: "range"});
        listeningMethodsRange.config.onChange.push(function(selectedDates, dateStr, instance){
            if (selectedDates.length === 2) {
                generateListeningOnOther(selectedDates)
                // fetch(`/analytics/charts?chart_type=downloads&date_range=custom&beginning_date=${selectedDates[0]}&end_date=${selectedDates[1]}`)
                //     .then(res => res.json())
                //     .then(data => {
                //         downloadChart.updateSeries([{data: data}], true);
                //         updateTotal(data, "downloads");
                //     })
            }
        })
        osRange.config.onChange.push(function(selectedDates, dateStr, instance){
            function clearOsRange() {
                osRange.clear()
            }
            let url = `/analytics/charts?chart_type=os`
            if (selectedDates.length === 2) {
                url = `/analytics/charts?chart_type=os&date_range=custom&beginning_date=${selectedDates[0]}&end_date=${selectedDates[1]}`
                osRangeClear.hidden = false
                osRangeClear.addEventListener('click', clearOsRange)
            } else {
                osRangeClear.hidden = true
                osRangeClear.removeEventListener('click', clearOsRange)
            }
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    if (!data.series?.length || !data.labels?.length) {
                        document.querySelector('#os-pie-data').style.display = 'none'
                        document.querySelector('#os-pie-no-data').removeAttribute('hidden')
                    } else {
                        document.querySelector('#os-pie-data').style.display = 'flex'
                        document.querySelector('#os-pie-no-data').setAttribute('hidden', '')
                        donutCharts[0].updateOptions({
                            series: data.series,
                            labels: data.labels
                        }, true);
                        createDonutChartTable(0, data)
                    }
                })
        })
        browsersRange.config.onChange.push(function(selectedDates, dateStr, instance){
            function clearBrowsersRange() {
                browsersRange.clear()
            }
            let url = `/analytics/charts?chart_type=listening_on_browser`
            if (selectedDates.length === 2) {
                url = `/analytics/charts?chart_type=listening_on_browser&date_range=custom&beginning_date=${selectedDates[0]}&end_date=${selectedDates[1]}`
                browsersRangeClear.hidden = false
                browsersRangeClear.addEventListener('click', clearBrowsersRange)
            } else {
                browsersRangeClear.hidden = true
                browsersRangeClear.removeEventListener('click', clearBrowsersRange)
            }
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    if (!data.series?.length || !data.labels?.length) {
                        document.querySelector('#browser-pie-data').style.display = 'none'
                        document.querySelector('#browser-pie-no-data').removeAttribute('hidden')
                    } else {
                        document.querySelector('#browser-pie-data').style.display = 'flex'
                        document.querySelector('#browser-pie-no-data').setAttribute('hidden', '')
                        donutCharts[1].updateOptions({
                            series: data.series,
                            labels: data.labels
                        }, true);
                        createDonutChartTable(1, data)
                    }
                })
        })
        timeRange.config.onChange.push(function(selectedDates, dateStr, instance){
            if (selectedDates.length === 2) {
                fetch(`/analytics/charts?chart_type=when_they_listen&date_range=custom&beginning_date=${selectedDates[0]}&end_date=${selectedDates[1]}`)
                    .then(res => res.json())
                    .then(data => {
                        timeChart.updateOptions({
                            series: [{
                                name: 'Downloads',
                                data: data.series
                            }],
                            xaxis: {
                                categories: data.labels,
                                tickPlacement: "on"
                            }
                        }, true);
                    })
            }
        })

        function downloadCSV(rows) {
            // Builds the csv
            let csvContent = "data:text/csv;charset=utf-8,";
            rows.forEach(function(rowArr) {
                let row = rowArr.join(",");
                csvContent += row + "\r\n";
            });
            // Create a dummy <a> tag and simulate a click to download the file then remove the tag
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "CoHost_Analytics_Data.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // const encodedUri = encodeURI(csvContent);
            // window.open(encodedUri);
        }
        document.querySelector("#ql-listening-methods-dl").addEventListener("click", function() {
            downloadCSV(topListeningMethodsData);
        });
        document.querySelector("#ql-browsers-dl").addEventListener("click", function() {
            downloadCSV(pieChartData[1]);
        });
        document.querySelector("#ql-os-dl").addEventListener("click", function() {
            console.log(pieChartData[0])
            downloadCSV(pieChartData[0]);
        });
        // For listening_on_other table
        let listeningSimpleBar;
        function generateListeningOnOther(dateRange){
            let url = "/analytics/charts?chart_type=listening_on_other"
            if (dateRange && dateRange.length === 2) {
                url += `&date_range=custom&beginning_date=${dateRange[0]}&end_date=${dateRange[1]}`
            }
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    let tempObj = {};
                    let total = 0;
                    for (let i = 0; i < data.labels.length; i++) {
                        if (data.labels[i] === null) {
                            data.labels.splice(i, 1);
                            data.series.splice(i, 1);
                        } else if (data.labels[i] != "") {
                            if (data.labels[i].toLowerCase().includes("apple")) {
                                [tempObj, total] = groupListeningMethods("Apple Podcasts", tempObj, total, data, i)
                            } else if (data.labels[i].toLowerCase().includes("amazon") || data.labels[i].toLowerCase() === "alexa media player") {
                                [tempObj, total] = groupListeningMethods("Amazon Podcasts", tempObj, total, data, i)
                            } else if (data.labels[i].toLowerCase().includes("google")) {
                                [tempObj, total] = groupListeningMethods("Google Podcasts", tempObj, total, data, i)
                            } else if (["bot", "okhttp", "asynchronous io library", "axios", "ffmpeg", "wget", "java runtime environment", "airr player", "curl", "unknown", "other"].includes(data.labels[i].toLowerCase())) {
                                [tempObj, total] = groupListeningMethods("Other", tempObj, total, data, i)
                            } else if (data.labels[i].toLowerCase().includes("roku")) {
                                [tempObj, total] = groupListeningMethods("Roku", tempObj, total, data, i)
                            } else if (data.labels[i].toLowerCase().includes("tunein")) {
                                [tempObj, total] = groupListeningMethods("TuneIn", tempObj, total, data, i)
                            } else {
                                tempObj[data.labels[i]] = data.series[i]
                                total += data.series[i]
                            }
                        }
                    }
                    sortedKeys = Object.keys(tempObj).sort(function(a,b){return tempObj[b]-tempObj[a]})
                    const listeningTable = document.querySelector("#listening-table");
                    // Resets the table
                    listeningTable.innerHTML = ""
                    let otherVal;
                    let otherPercent;
                    let otherGradient;
                    topListeningMethodsData = [["Application", "Total Downloads", "%"]]
                    let ranking = 0;
                    for (let i = 0; i < sortedKeys.length; i++) {
                        let percent_value = tempObj[sortedKeys[i]] * 100.0 / total
                        if (percent_value > 99) {
                            percent_value = ">99"
                        } else if (percent_value < 1 && percent_value > 0) {
                            percent_value = "<1"
                        } else {
                            percent_value = Math.round(percent_value)
                        }
                        if (sortedKeys[i] === "Other") {
                            otherVal = tempObj[sortedKeys[i]]
                            otherPercent = percent_value
                            otherGradient = Math.round(tempObj[sortedKeys[i]] * 100.0 / total);
                        } else {
                            listeningTable.innerHTML += `
                            <tr class="h-20 flex w-full"  style="background: linear-gradient(90deg, rgba(56, 21, 153, 0.2) ${Math.round(tempObj[sortedKeys[i]] * 100.0 / total)}%, #FFFFFF 0%)">
                                <td class="flex items-center w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    #${ranking+1}
                                </td>
                                <td class="flex items-center w-6/12 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    ${sortedKeys[i]}
                                </td>
                                <td class="flex items-center w-3/12 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    ${tempObj[sortedKeys[i]]}
                                </td>
                                <td class="flex items-center w-2/12 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    ${percent_value}%
                                </td>
                            </tr>`;
                            topListeningMethodsData.push([sortedKeys[i], tempObj[sortedKeys[i]], percent_value])
                            ranking += 1;
                        }

                    }
                    if (otherVal && otherPercent && otherGradient) {
                        listeningTable.innerHTML += `
                            <tr class="h-20 flex w-full"  style="background: linear-gradient(90deg, rgba(56, 21, 153, 0.2) ${otherGradient}%, #FFFFFF 0%)">
                                <td class="flex items-center w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    #${sortedKeys.length}
                                </td>
                                <td class="flex items-center w-6/12 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Other
                                </td>
                                <td class="flex items-center w-3/12 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    ${otherVal}
                                </td>
                                <td class="flex items-center w-2/12 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    ${otherPercent}%
                                </td>
                            </tr>`;
                        topListeningMethodsData.push(["Other", otherVal, otherPercent])
                    }
                    if (dateRange) {
                        listeningSimpleBar.unMount()
                    }
                    listeningSimpleBar = new SimpleBar(listeningTable, { autoHide: false });
                })
        }
        // Initializes table on load
        generateListeningOnOther()
        // For pie charts
        let osSimpleBar;
        let browserSimpleBar;
        let donutSimpleBars = [osSimpleBar, browserSimpleBar]
        Promise.all(audienceParams.map(param =>
        fetch(`/analytics/charts?chart_type=${param}`).then(res => res.json())
        )).then(data => {
        for (let i = 0; i < audienceNames.length; i++) {
            // Removes null values
            for (let j = 0; j < data[i].labels.length; j++) {
                if (data[i].labels[j] === null) {
                    data[i].labels.splice(j, 1);
                    data[i].series.splice(j, 1);
                }
            }

            const audienceName = audienceNames[i]
            if (!data[i].series?.length || !data[i].labels?.length) {
                switch (audienceName) {
                    case 'Operating system':
                        document.querySelector('#os-pie-data').style.display = 'none'
                        document.querySelector('#os-pie-no-data').removeAttribute('hidden')
                        break
                    case 'Browsers':
                        document.querySelector('#browser-pie-data').style.display = 'none'
                        document.querySelector('#browser-pie-no-data').removeAttribute('hidden')
                        break
                }
            } else {
                switch (audienceName) {
                    case 'Operating system':
                        document.querySelector('#os-pie-data').style.display = 'flex'
                        document.querySelector('#os-pie-no-data').setAttribute('hidden', '')
                        break
                    case 'Browsers':
                        document.querySelector('#browser-pie-data').style.display = 'flex'
                        document.querySelector('#browser-pie-no-data').setAttribute('hidden', '')
                        break
                }
                var options = {
                    colors: chartColors,
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                minAngleToShowLabel: 5
                            },
                            donut: {
                                size: "65%"
                            }
                        }
                    },
                    legend: {
                        show: false
                    },
                    chart: {
                        height: 380,
                        width: "100%",
                        type: "donut",
                        fontFamily: "manrope"
                    },
                    series: data[i].series,
                    labels: data[i].labels,
                    tooltip: {
                        y: {
                            formatter: function(value) {
                                return value + " Downloads"
                            }
                        }
                    }
                };

                donutCharts[i] = new ApexCharts(document.querySelector(audienceSelectors[i] + "-pie"), options);

                donutCharts[i].render();
                createDonutChartTable(i, data[i])
            }
        }
        });
        function createDonutChartTable(chartID, data) {
            // 0 = OS
            // 1 = Browser
            let unknownValue = 0;
            let unknownColor;
            if (chartID === 0) {
                pieChartData[0] = [["Operating System", "Downloads"]]
            } else if (chartID === 1) {
                pieChartData[1] = [["Browsers", "Downloads"]]
            }
            document.querySelector(audienceLegend[chartID]).innerHTML = ""
            for (let j = 0; j < data.labels.length; j++) {
                if (data.labels[j].toLowerCase() === "unknown") {
                    unknownValue += data.series[j]
                    unknownColor = chartColors[j % chartColors.length]
                } else {
                    document.querySelector(audienceLegend[chartID]).innerHTML += `
                    <div class="flex justify-between items-center space-y-2">
                        <div class="flex justify-center items-center">
                        <span class="rounded-full h-5 w-5 items-center justify-center mr-3" style="background-color: ${chartColors[j % chartColors.length]}"></span>
                        <span class="w-44 break-words">${data.labels[j]}</span>
                        </div>
                        <div>${data.series[j]}</div>
                    </div>`
                    pieChartData[chartID].push([data.labels[j], data.series[j]])
                }
            }
            if (unknownValue > 0 && unknownColor) {
                document.querySelector(audienceLegend[chartID]).innerHTML += `
                <div class="flex justify-between items-center space-y-2">
                    <div class="flex justify-center items-center">
                    <span class="rounded-full h-5 w-5 items-center justify-center mr-3" style="background-color: ${unknownColor}"></span>
                    <span class="w-44 break-words">Unknown</span>
                    </div>
                    <div>${unknownValue}</div>
                </div>`
                pieChartData[chartID].push(["Unknown", unknownValue])
            }
            console.log("Pie chart data:", pieChartData[chartID])
            if (donutSimpleBars[chartID]) {
                donutSimpleBars[chartID].unMount()
            }
            donutSimpleBars[chartID] = new SimpleBar(document.querySelector(audienceLegend[chartID]));
        }
        // For column chart (when they listen)
        fetch("/analytics/charts?chart_type=when_they_listen")
        .then(res => res.json())
        .then(data => {
            let options = {
                colors: ["#391599"],
                series: [{
                    name: 'Downloads',
                    data: data.series
                }],
                chart: {
                    toolbar: {
                        export: {
                            csv: {
                                filename: "CoHost_Analytics_Data",
                                headerCategory: "Time"
                            }
                        }
                    },
                    type: 'bar',
                    height: 380,
                    zoom: {
                        type: "xy"
                    },
                    fontFamily: "manrope"
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: data.labels,
                    tickPlacement: "on"
                }
            };
            timeChart = new ApexCharts(document.querySelector("#when-they-listen"), options);
            timeChart.render();
        });
    }

    // Empty state page for Download
    if (document.querySelector("#empty-chart-download")) {
        const downloadNamesEmpty = ["Downloads", "Unique Listeners"];
        const downloadSelectorsEmpty = ["#empty-chart-download", "#empty-chart-unique"];
        const chartDataEmpty = [JSON.parse(document.querySelector("#empty-chart-download").dataset.chart), JSON.parse(document.querySelector("#empty-chart-unique").dataset.chart)]
        // For download charts
        for (let i = 0; i < downloadNamesEmpty.length; i++) {
            let options = {
                chart: {
                    height: 380,
                    width: "100%",
                    type: "area",
                },
                series: [
                {
                    name: downloadNamesEmpty[i],
                    data: chartDataEmpty[i]
                }
                ],
                xaxis: {
                type: 'datetime'
                }
            };
    
            let chart = new ApexCharts(document.querySelector(downloadSelectorsEmpty[i]), options);
    
            chart.render();
        }

        // Multiple Episode stuff
        const element = document.querySelector('#empty-multiple-select');
        const choices = new Choices(element, {
            removeItemButton: true,
            maxItemCount: 5,
            placeholderValue: "Choose up to 5 episodes to compare.",
            shouldSort: false
        });
        choices.setChoiceByValue(JSON.parse(element.dataset.titles));


        // Instantiate the multiple episode chart
        let multipleChartOptions = {
            colors: ["#FFA153", "#FF547F", "#3AA6EC", "#9550EE", "#A5ECE8"],
            series: JSON.parse(document.querySelector("#empty-chart-multiple").dataset.chart),
            chart: {
                height: 380,
                width: "100%",
                type: "line",
                zoom: {
                    type: 'xy'
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                type: 'datetime'
            },
            tooltip: {
                shared: true,
                    x: {
                    format: "dd MMM yyyy"
                    },
            }
        };
        
        let multipleChart = new ApexCharts(document.querySelector("#empty-chart-multiple"), multipleChartOptions);
        
        multipleChart.render();
    }

    // Empty state page for unique
    if (document.querySelector("#empty-listening-using-pie")) {
        const audienceNamesEmpty = ["Listening using", "Operating system", "Apps", "Browsers"];
        const audienceSelectorsEmpty = ["#empty-listening-using", "#empty-os", "#empty-listening-other", "#empty-listening-browser"]
        const chartDataEmpty = [JSON.parse(document.querySelector("#empty-listening-using-pie").dataset.chart),
                        JSON.parse(document.querySelector("#empty-os-pie").dataset.chart),
                        JSON.parse(document.querySelector("#empty-listening-other-pie").dataset.chart),
                        JSON.parse(document.querySelector("#empty-listening-browser-pie").dataset.chart)]
        // For pie charts
        for (let i = 0; i < audienceNamesEmpty.length; i++) {
            let options = {
                colors: ["#FFA153", "#FF547F", "#3AA6EC", "#9550EE", "#A5ECE8"],
                plotOptions: {
                    pie: {
                        dataLabels: {
                            minAngleToShowLabel: 5
                        },
                        donut: {
                            size: "50%"
                        }
                    }
                },
                legend: {
                    position: "top"
                },
                chart: {
                    height: 380,
                    width: "100%",
                    type: "donut"
                },
                series: chartDataEmpty[i].series,
                labels: chartDataEmpty[i].labels
            };

            let chart = new ApexCharts(document.querySelector(audienceSelectorsEmpty[i] + "-pie"), options);

            chart.render();
        }
        // For bar charts
        for (let i = 0; i < audienceNamesEmpty.length; i++) {
            let options = {
                colors: ["#391599"],
                chart: {
                    height: 380,
                    width: "100%",
                    type: "bar"
                },
                plotOptions: {
                    bar: {
                    borderRadius: 4,
                    horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                series: [{
                    name: audienceNamesEmpty[i],
                    data: chartDataEmpty[i].series
                }],
                xaxis: {
                    categories: chartDataEmpty[i].labels
                },
                tooltip: {
                    y: {                        
                        formatter: function(val) {
                            return Math.floor(val)
                        }
                    }
                }
            };

            let chart = new ApexCharts(document.querySelector(audienceSelectorsEmpty[i] + "-bar"), options);

            chart.render();
        }
        
            let options = {
                colors: ["#391599"],
                series: [{
                    name: 'Plays',
                    data: JSON.parse(document.querySelector("#empty-when-they-listen").dataset.chart).series
                }],
                chart: {
                    type: 'bar',
                    height: 380,
                    zoom: {
                        type: "xy"
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: JSON.parse(document.querySelector("#empty-when-they-listen").dataset.chart).labels,
                    tickPlacement: "on"
                }
            };
            let chart = new ApexCharts(document.querySelector("#empty-when-they-listen"), options);
            chart.render();
    }
    
})