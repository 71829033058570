import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).
import 'md-gum-polyfill'
import ResizeObserver from 'resize-observer-polyfill'

import Uppy from '@uppy/core'
import Transloadit from '@uppy/transloadit'
import Dashboard from '@uppy/dashboard'

require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')

window.ResizeObserver ??= ResizeObserver

document.addEventListener('alpine:init', () => {

    const uppy = new Uppy({
        id: 'content_insertion_show_page',
        debug: true,
        autoProceed: false,
        restrictions: {
            maxFileSize: 150 * 1024 * 1024,
            minFileSize: 1,
            maxTotalFileSize: 150 * 1024 * 1024,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: ['audio/*'],
            requiredMetaFields: [],
        },
        locale: {
            strings: {
                youCanOnlyUploadFileTypes: 'You can only upload audio files',
            },
        },
    })


    uppy.use(Dashboard, {
        trigger: document.getElementById('uppy-trigger'),
        closeAfterFinish: true,
        showProgressDetails: true
    })

    uppy.use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
            auth: {
                key: '1b1495040f1949e3a024d085dd493580'
            },
            template_id: 'bbbdb60e38c0403d824dc05ef27a6260'
        },
        waitForEncoding: false,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        fields: {
            'audio': 'audio',
            'location': 'audio/123/123',
        },
    })

    uppy.on('complete', (result) => {
        console.log('successful files:', result.successful)
        console.log('failed files:', result.failed)
    })
})