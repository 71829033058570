import flatpickr from "flatpickr";
import {download_csv, download_pdf} from "../cohost_utils";

const today = new Date();
const TODAYS_DATE = today.toISOString().split('T')[0]
const SEVEN_DAYS_AGO = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
const FOURTEEN_DAYS_AGO = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
const ONE_MONTH_AGO = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
const SIX_MONTHS_AGO = new Date(today.getTime() - 180 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]

document.addEventListener('alpine:init', () => {

    Alpine.data('episode_breakdown_x_data', () => ({

            init() {
                this.load_data().then(r => {
                    this.build_charts()
                    if (this.location_breakdown.length > 0) {
                        this.location_breakdown_csv = Object.entries(this.location_breakdown[0])
                    }

                    if (this.industry_breakdown.series.length > 0) {
                        this.industry_breakdown_csv = this.industry_breakdown.labels.map((item, index) => [item, this.industry_breakdown.series[index]])
                    }
                })
                this.initialize_flatpickr()
            },
            company_breakdown: [],
            industry_breakdown: [],
            company_size_breakdown: [],
            location_breakdown: [],
            revenue_breakdown: [],
            location_breakdown_csv: [],
            industry_breakdown_csv: [],
            top_company: 'Loading ...',
            top_industry: 'Loading ...',
            top_location: 'Loading ...',
            company_breakdown_start_date: '',
            company_breakdown_end_date: '',
            industry_breakdown_start_date: '',
            industry_breakdown_end_date: '',
            company_size_breakdown_start_date: '',
            company_size_breakdown_end_date: '',
            location_breakdown_start_date: '',
            location_breakdown_end_date: '',
            revenue_breakdown_start_date: '',
            revenue_breakdown_end_date: '',
            company_breakdown_range: '7D',
            industry_breakdown_range: '7D',
            company_size_breakdown_range: '7D',
            location_breakdown_range: '7D',
            revenue_breakdown_range: '7D',
            episode_title: '',
            episode_id: '',
            download_screen_text: 'Download Snapshot',
            flatpickr_instances: {},
            donut_chart: null,
            simpleBars: {
                company_breakdown: null,
                industry_breakdown: null,
                company_size_breakdown: null,
                company_revenue_breakdown: null,
                company_location_breakdown: null
            },
            async load_data() {
                let data = this.$refs.data

                let episode_data_all_time_stats_url = data.dataset.episodeAllTimeStatsUrl
                let episode_company_breakdown_url = data.dataset.episodeCompanyBreakdownUrl
                let episode_industry_breakdown_url = data.dataset.episodeIndustryBreakdownUrl
                let episode_company_size_breakdown_url = data.dataset.episodeCompanySizeBreakdownUrl
                let episode_location_breakdown_url = data.dataset.episodeLocationBreakdownUrl
                let episode_revenue_breakdown_url = data.dataset.episodeRevenueBreakdownUrl
                this.episode_title = data.dataset.episodeTitle
                this.episode_id = data.dataset.episodeId


                const urls = [
                    episode_data_all_time_stats_url,
                    episode_company_breakdown_url,
                    episode_industry_breakdown_url,
                    episode_company_size_breakdown_url,
                    episode_location_breakdown_url,
                    episode_revenue_breakdown_url
                ];

                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                        episode_id: this.episode_id
                    })
                };

                const promises = urls.map(url => fetch(url, options));
                const responses = await Promise.all(promises);


                const all_stats_data_response = responses[0];
                const all_stats_data = await all_stats_data_response.json();
                if (all_stats_data.statusCode === 400) {
                    this.top_company = 'No data'
                    this.top_industry = 'No data'
                    this.top_location = 'No data'
                } else if (all_stats_data.body === null) {
                    this.top_company = 'No data'
                    this.top_industry = 'No data'
                    this.top_location = 'No data'
                } else {
                    this.top_industry = all_stats_data.body.top_industry
                    this.top_company = all_stats_data.body.top_company
                    this.top_location = all_stats_data.body.top_location
                }

                const company_breakdown_response = responses[1];
                const company_breakdown = await company_breakdown_response.json();
                if (company_breakdown.statusCode === 400) {
                    this.company_breakdown = 'No data'
                } else {
                    console.log('company_breakdown', company_breakdown.body)
                    this.company_breakdown = company_breakdown.body
                }

                const industry_breakdown_response = responses[2];
                const industry_breakdown = await industry_breakdown_response.json();
                if (industry_breakdown.statusCode === 400) {
                    this.industry_breakdown = 'No data'
                } else {
                    this.industry_breakdown = industry_breakdown.body
                }

                const company_size_breakdown_response = responses[3];
                const company_size_breakdown = await company_size_breakdown_response.json();
                if (company_size_breakdown.statusCode === 400) {
                    this.company_size_breakdown = 'No data'
                } else {
                    this.company_size_breakdown = company_size_breakdown.body
                }

                const location_breakdown_response = responses[4];
                const location_breakdown = await location_breakdown_response.json();
                if (location_breakdown.statusCode === 400) {
                    this.location_breakdown = 'No data'
                } else {
                    this.location_breakdown = location_breakdown.body
                }

                const revenue_breakdown_response = responses[5];
                const revenue_breakdown = await revenue_breakdown_response.json();
                if (revenue_breakdown.statusCode === 400) {
                    this.revenue_breakdown = 'No data'
                } else {
                    this.revenue_breakdown = revenue_breakdown.body
                }
            },
            build_charts() {
                this.build_table("company_breakdown_table", this.company_breakdown);
                this.build_industry_chart(this.industry_breakdown);
                this.build_table("company_size_table", this.company_size_breakdown);
                this.build_geo_items(this.location_breakdown);
                this.build_table("company_revenue_table", this.revenue_breakdown);
            },
            build_geo_items(data) {
                const chartEl = Chartkick.charts["brands-map"]
                chartEl.getChartObject()
                chartEl.updateData(data[0])

                const el = this.$refs.company_locations_table

                el.innerHTML = ``

                const countries = Object.keys(data[0])

                for (let i = 0; i < countries.length; i++) {
                    el.innerHTML += `
                <tr class="h-20 flex w-full">
                <td class="w-1/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                    ${i + 1}
                </td>
                <td class="w-9/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                    ${countries[i]}
                </td>
                <td class="w-2/12 flex items-center justify-end px-6 py-4 text-sm font-medium text-gray-900">
                    ${data[0][countries[i]]}%
                </td>
                </tr>
                `
                }
                this.simpleBars["company_locations_table"] = new SimpleBar(el);
            },
            build_industry_chart(data) {
                if (this.donut_chart) {
                    this.donut_chart.destroy()
                }
                const chartColors = ["#1C7EBD", "#5EBAB4", "#7237BE", "#E9D2F4", "#B59194"]
                const options = {
                    colors: chartColors,
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                minAngleToShowLabel: 5
                            },
                            donut: {
                                size: "65%"
                            }
                        }
                    },
                    legend: {
                        show: false
                    },
                    chart: {
                        height: 380,
                        width: "100%",
                        type: "donut",
                        fontFamily: "manrope"
                    },
                    series: data.series,
                    labels: data.labels,
                    tooltip: {
                        y: {
                            formatter: function (value) {
                                return value + "% Downloads"
                            }
                        }
                    },
                    noData: {
                        text: "No data for given range",
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '14px',
                            fontFamily: undefined
                        }
                    }
                };

                this.donut_chart = new ApexCharts(document.querySelector("#industry-chart"), options);
                this.donut_chart.render();

                const el = this.$refs.industry_legend
                el.innerHTML = ``
                for (let j = 0; j < data.labels.length; j++) {
                    el.innerHTML += `
                <div class="flex justify-between items-center space-y-2">
                    <div class="flex justify-center items-center">
                    <span class="rounded-full h-5 w-5 items-center justify-center mr-3" style="background-color: ${chartColors[j % chartColors.length]}"></span>
                    <span class="w-44 break-words">${data.labels[j]}</span>
                    </div>
                    <div>${data.series[j]}%</div>
                </div>`
                }
                this.simpleBars["industry_breakdown"] = new SimpleBar(this.$refs.industry_legend);
            },
            build_table(table_name, data) {
                if (table_name === "company_breakdown_table") {
                    const el = this.$refs.company_breakdown_table
                    el.innerHTML = ``

                    data.forEach((item) => {
                        el.innerHTML += `
                    <tr class="h-20 flex w-full"  style="background: linear-gradient(90deg, rgba(56, 21, 153, 0.2) ${item[4]}, #FFFFFF 0%)">
                    <td class="w-1/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[0]}
                    </td>
                    <td class="w-5/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[1]}
                    </td>
                    <td class="w-2/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[2]}
                    </td>
                    <td class="w-2/12 flex items-center justify-end px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[3]}
                    </td>
                    <td class="w-2/12 flex items-center justify-end px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[4]}
                    </td>
                    </tr>
                    `
                    })
                    this.simpleBars["company_breakdown"] = new SimpleBar(this.$refs.company_breakdown_table);
                } else if (table_name === "company_size_table") {
                    const el = this.$refs.company_size_table
                    el.innerHTML = ``

                    data.forEach((item) => {
                        el.innerHTML += `
                    <tr class="h-20 flex w-full"  style="background: linear-gradient(90deg, rgba(56, 21, 153, 0.2) ${item[2]}, #FFFFFF 0%)">
                    <td class="w-1/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[0]}
                    </td>
                    <td class="w-9/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[1]}
                    </td>
                    <td class="w-2/12 flex items-center justify-end px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[2]}
                    </td>
                    </tr>
                    `
                    })
                    this.simpleBars["company_size_breakdown"] = new SimpleBar(this.$refs.company_size_table);
                } else if (table_name === "company_revenue_table") {
                    const el = this.$refs.company_revenue_table
                    el.innerHTML = ``

                    data.forEach((item) => {
                        el.innerHTML += `
                    <tr class="h-20 flex w-full"  style="background: linear-gradient(90deg, rgba(56, 21, 153, 0.2) ${item[2]}, #FFFFFF 0%)">
                    <td class="w-1/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[0]}
                    </td>
                    <td class="w-9/12 flex items-center px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[1]}
                    </td>
                    <td class="w-2/12 flex items-center justify-end px-6 py-4 text-sm font-medium text-gray-900">
                        ${item[2]}
                    </td>
                    </tr>
                    `
                    })
                    this.simpleBars["company_revenue_breakdown"] = new SimpleBar(this.$refs.company_revenue_table);
                }
            },
            initialize_flatpickr() {
                const datePickers = ['company_breakdown',
                    'industry_breakdown',
                    'company_size_breakdown',
                    'location_breakdown',
                    'revenue_breakdown']

                datePickers.forEach(picker => {
                    const el = this.$refs[`${picker}_datepicker`]

                    let fp = flatpickr(el, {
                        mode: "range",
                        dateFormat: "Y-m-d",
                        minDate: "2023-03-01",
                        maxDate: "today",
                        placeholder: '30 day range',
                        onChange: (selectedDates, dateStr, instance) => {
                            this[`${picker}_start_date`] = selectedDates[0]
                            this[`${picker}_end_date`] = selectedDates[1]
                            if (selectedDates[0] && selectedDates[1]) {
                                this[`${picker}_request`]()
                                this[`${picker}_range`] = null
                            }
                        }
                    })
                    this.flatpickr_instances[picker] = fp
                })
            },
            send_request(request_type, start_date, end_date, range_request = false) {
                let data = this.$refs.data
                let url = ''
                let that = this
                const default_date_difference = parseInt(this.$refs.data.dataset.defaultDateDifference)

                start_date = start_date === '' ? new Date(today.getTime() - default_date_difference * 24 * 60 * 60 * 1000).toISOString().split('T')[0] : start_date
                end_date = end_date === '' ? new Date().toISOString().split('T')[0] : end_date

                if (request_type === 'company_breakdown') {
                    url = data.dataset.episodeCompanyBreakdownUrl
                } else if (request_type === 'industry_breakdown') {
                    url = data.dataset.episodeIndustryBreakdownUrl
                } else if (request_type === 'company_size_breakdown') {
                    url = data.dataset.episodeCompanySizeBreakdownUrl
                } else if (request_type === 'location_breakdown') {
                    url = data.dataset.episodeLocationBreakdownUrl
                } else if (request_type === 'revenue_breakdown') {
                    url = data.dataset.episodeRevenueBreakdownUrl
                } else {
                    alert('Something went wrong. Unable to send request')
                }

                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                        episode_id: that.episode_id,
                        start_date: start_date,
                        end_date: end_date
                    })
                }

                fetch(url, options)
                    .then(response => response.json())
                    .then(data => {
                        switch (request_type) {
                            case 'company_breakdown':
                                // This resets the range request if the user changes the date range
                                if (!range_request) {
                                    this.company_breakdown_range = ''
                                }
                                this.build_table("company_breakdown_table", data.body);
                                that.company_breakdown = data.statusCode === 400 ? [] : data.body;
                                break;
                            case 'industry_breakdown':
                                // This resets the range request if the user changes the date range
                                if (!range_request) {
                                    this.industry_breakdown_range = ''
                                }
                                data.statusCode === 400 ? this.clear_chart(request_type) : this.build_industry_chart(data.body);
                                that.industry_breakdown = data.statusCode === 400 ? [] : data.body;
                                break;
                            case 'company_size_breakdown':
                                // This resets the range request if the user changes the date range
                                if (!range_request) {
                                    this.company_size_breakdown_range = ''
                                }
                                this.build_table("company_size_table", data.body);
                                that.company_size_breakdown = data.statusCode === 400 ? [] : data.body;
                                break;
                            case 'location_breakdown':

                                // This resets the range request if the user changes the date range
                                if (!range_request) {
                                    this.location_breakdown_range = ''
                                }
                                data.statusCode === 400 ? this.clear_chart(request_type) : this.build_geo_items(data.body);
                                that.location_breakdown = data.statusCode === 400 ? [] : data.body;
                                break;
                            case 'revenue_breakdown':

                                // This resets the range request if the user changes the date range
                                if (!range_request) {
                                    this.revenue_breakdown_range = ''
                                }

                                this.build_table("company_revenue_table", data.body);
                                that.revenue_breakdown = data.statusCode === 400 ? [] : data.body;
                                break;
                            default:
                                console.error(`Unknown request type: ${request_type}`);
                                break;
                        }
                    })
            },
            clear_chart(type) {
                switch (type) {
                    case 'company_breakdown':
                        this.$refs.company_breakdown_table.innerHTML = ""
                        break;
                    case 'industry_breakdown':
                        this.build_industry_chart({series: [], labels: []})
                        break;
                    case 'company_size_breakdown':
                        this.$refs.company_size_table.innerHTML = ""
                        break;
                    case 'location_breakdown':
                        const chartEl = Chartkick.charts["brands-map"]
                        chartEl.getChartObject()
                        chartEl.updateData(0)
                        this.$refs.company_locations_table.innerHTML = ""
                        break;
                    case 'revenue_breakdown':
                        this.$refs.company_revenue_table.innerHTML = ""
                        break;
                    default:
                        console.error(`Unknown request type for clearing the chart: ${type}`);
                        break;
                }
            },
            company_breakdown_request() {
                this.send_request('company_breakdown', this.company_breakdown_start_date, this.company_breakdown_end_date)
            },
            industry_breakdown_request() {
                this.send_request('industry_breakdown', this.industry_breakdown_start_date, this.industry_breakdown_end_date)
            },
            company_size_breakdown_request() {
                this.send_request('company_size_breakdown', this.company_size_breakdown_start_date, this.company_size_breakdown_end_date)
            },
            location_breakdown_request() {
                this.send_request('location_breakdown', this.location_breakdown_start_date, this.location_breakdown_end_date)
            },
            revenue_breakdown_request() {
                this.send_request('revenue_breakdown', this.revenue_breakdown_start_date, this.revenue_breakdown_end_date)
            },
            range_query(request_type, range_value) {
                // Map range values to date ranges
                const range_map = {
                    '7D': [SEVEN_DAYS_AGO, TODAYS_DATE],
                    '14D': [FOURTEEN_DAYS_AGO, TODAYS_DATE],
                    '1M': [ONE_MONTH_AGO, TODAYS_DATE],
                    '6M': [SIX_MONTHS_AGO, TODAYS_DATE]
                };

                // This way we can get the range value array from the range map. This consists of the start and end date
                const range = range_map[range_value];
                if (range) {
                    const send_data = (type) => {
                        const date_range = range_map[range_value];
                        this[type + '_range'] = range_value;
                        this.send_request(type, date_range[0], date_range[1], true);
                    }
                    switch (request_type) {
                        case 'company_breakdown':
                            this.flatpickr_instances['company_breakdown'].clear()
                            send_data(request_type);
                            break;
                        case 'industry_breakdown':
                            this.flatpickr_instances['industry_breakdown'].clear()
                            send_data(request_type);
                            break;
                        case 'company_size_breakdown':
                            this.flatpickr_instances['company_size_breakdown'].clear()
                            send_data(request_type);
                            break;
                        case 'location_breakdown':
                            this.flatpickr_instances['location_breakdown'].clear()
                            send_data(request_type);
                            break;
                        case 'revenue_breakdown':
                            this.flatpickr_instances['revenue_breakdown'].clear()
                            send_data(request_type);
                            break;
                        default:
                            console.log(`Invalid request type: ${request_type}`);
                    }
                } else {
                    console.log(`Invalid range value: ${range_value}`);
                }
            },
            download_csv(arr, filename) {
                download_csv(arr, filename)
            },
            download_pdf() {
                this.download_screen_text = 'Generating PDF...';
                download_pdf('ql-screenshot', `${this.episode_title}-B2B-${TODAYS_DATE}.pdf`).then(() => {
                    this.download_screen_text = 'Download Snapshot';
                    console.log(this.download_screen_text)
                });
            }
        })
    )
})