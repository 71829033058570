// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
// Take a look at the below package to see if it's required
import 'alpine-hotwire-turbo-adapter'
// --------- The one above ---------
import "custom"
import "chartkick/chart.js"

Rails.start()
ActiveStorage.start()

require("stylesheets/application.scss")

// This is to import media files
function importAll(r) {
    r.keys().forEach(r);
}

importAll(require.context('../images/', true, /\.(svg|jpg|png|ico)$/));
require("../custom/temporary_audio_uploader")
require("../custom/temporary_cover_art_uploader")
require("../custom/flatpickr")
require("../custom/payment")
require("../custom/chart")
require("../custom/dashboard_analytics")


String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
