import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).
import 'md-gum-polyfill'

import Uppy from '@uppy/core'
import Transloadit from '@uppy/transloadit'
import Dashboard from '@uppy/dashboard'

require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')

document.addEventListener('alpine:init', () => {


    Alpine.data('admin_episodes_show_x_data', () => ({
        init() {
            uppy_episode_transcript(this);
        }
    }));
});

function uppy_episode_transcript(alpine) {
    let signature = document.getElementById('ql-data-points').dataset.signature
    let template_id = document.getElementById('ql-data-points').dataset.template
    let episode_id = document.getElementById('ql-data-points').dataset.episodeId
    let show_id = document.getElementById('ql-data-points').dataset.showId
    let env = document.getElementById('ql-data-points').dataset.env
    let post_url = document.getElementById('ql-data-points').dataset.postUrl

    const uppy = new Uppy({
        id: 'uppy-episode-transcript',
        debug: true,
        autoProceed: true,
        restrictions: {
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: [".VTT", ".vtt"],
            requiredMetaFields: [],
        },
        locale: {
            strings: {
                youCanOnlyUploadFileTypes: 'You can only upload SRT transcript files',
            },
        },
    })


    uppy.use(Dashboard, {
        trigger: document.getElementById('content-uppy-trigger-transcript'),
        closeAfterFinish: true,
        showProgressDetails: true,
        allowMultipleUploadBatches: false,
        note: 'Please upload an SRT file for the episode transcript',
    })

    console.log("signature", signature)
    console.log("template_id", template_id)
    console.log("episode_id", episode_id)
    console.log("show_id", show_id)
    console.log("env", env)

    let options = {
        service: 'https://api2.transloadit.com',
        params: {
            auth: {
                key: signature,
            },
            template_id: template_id
        },
        waitForMetadata: true,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        fields: {
            show_id: show_id,
            episode_id: episode_id,
            env: env
        }
    }

    uppy.use(Transloadit, options);

    uppy.on('upload-error', (file, error, response) => {
        if (error.isNetworkError) {
            alert("The audio file was not uploading\n\n" +
                "If you are uploading from an office network, please check with your IT department regarding firewall settings. \n\n" +
                "Error: " + error.message)
        }
    })

    uppy.on('transloadit:complete', (assembly) => {
        uppy.cancelAll()
        uppy.getPlugin('Dashboard').closeModal()
        // Make a post request to the server to update the episode transcript
        let data = {
            show_id: show_id,
            id: episode_id
        }

        fetch(post_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Something went wrong');
            }
        }).then(data => {
            console.log(data)
            alert('Transcript uploaded successfully. Click ok to reload page')
            window.location.reload()
        }).catch(error => {
            alert('There was an error uploading the transcript. Please try again.')
        })
    })
}