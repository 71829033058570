import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).
import 'md-gum-polyfill'

import Uppy from '@uppy/core'
import Transloadit from '@uppy/transloadit'
import Dashboard from '@uppy/dashboard'

require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')

document.addEventListener('alpine:init', () => {

    Alpine.data('new_content_insertion_x_data', () => ({
        init() {
            let data = this.$refs.new_content_insertion_data.dataset.stage
            if (data === 'upload_content') {
                uppy_content_insertion(this)
            }
            this.episode_data = JSON.parse(this.$refs.episode_data.dataset.episodes).map((episode) => {
                return {
                    id: episode[0],
                    title: episode[1],
                    episode_season: episode[2],
                    episode_number: episode[3],
                    episode_stage: episode[4],
                    content: episode[5] === undefined ? [] : episode[5]
                }
            })
            this.$watch('hide_remove_content_modal_session', (value) => {
                if (value) {
                    sessionStorage.setItem('hide_remove_content_modal_session', 'true')
                } else {
                    sessionStorage.removeItem('hide_remove_content_modal_session')
                }
            })
            this.$watch('content_insertions', (val) => {
                console.log(val)
            })
            this.$watch('pre_roll_all', (val) => {
                let that = this
                if (val === false) {
                    // Iterate through content_insertions object and delete the values which contain the string 'pre_roll'
                    Object.keys(this.content_insertions).forEach(function (key) {
                        if (that.content_insertions[key].includes('pre_roll')) {
                            delete that.content_insertions[key]
                        }
                    })

                }
                that._clean_content_insertion_empty_values()
            })
            this.$watch('post_roll_all', (val) => {
                let that = this
                if (val === false) {
                    // Iterate through content_insertions object and delete the values which contain the string 'post_roll'
                    Object.keys(this.content_insertions).forEach(function (key) {
                        console.log(that.content_insertions[key])
                        if (that.content_insertions[key].includes('post_roll')) {
                            delete that.content_insertions[key]
                        }
                    })
                }
                that._clean_content_insertion_empty_values()
            })

            this.$watch('search_string', (val) => {
                if (val.length === 0) {
                    this.in_search_mode = false;
                    this.current_page = 0;
                    this.$refs.clear_button.classList.add("hidden")
                } else {
                    this.in_search_mode = true;
                    this.search_episode_data = this.episode_data.filter(episode => episode.title.toLowerCase().includes(val.toLowerCase()))
                    this.$refs.clear_button.classList.remove("hidden")
                }
                this.update_page_component()
                this.load_data_on_page();
            })

            this.$watch('current_page', (val) => {
                this.refresh_content_insertions()
            })

            this.setup_initial_table();

            const contentInsertion = JSON.parse(this.$refs.new_content_insertion_data.dataset.contentInsertion)
            if (contentInsertion) {
                this.content_name = contentInsertion.content_name
                this.content_type = contentInsertion.content_type
                this.content_insertion_id = contentInsertion.id
                this.content_url = contentInsertion.content_url
                if (this.content_url) {
                    let audio_div = document.getElementById('ql-uploaded-audio-insert')
                    if (audio_div) {
                        audio_div.setAttribute('src', this.content_url)
                        audio_div.load()
                        this.audio_upload = 'yes'
                        handleAudioDuration();
                    }

                }
            }
        },
        content_name: '',
        audio_upload: 'no',
        content_type: '',
        content_insertion_id: '',
        content_url: '',
        search_string: '',
        episode_data: [], //This holds the data for the episodes from the backend
        search_episode_data: [], // This holds the data for the episodes when searched
        current_page_episode_data: [], // This holds the data for the episodes of the current page
        content_insertions: [], // This holds the content insertions for the episode
        remove_content_modal: false,
        pre_roll_all: false,
        post_roll_all: false,
        bulk_add_pre_post_modal: false,
        selected_pre_post_text: '',
        selected_pre_post_header: '',
        selected_pre_post_option: '',
        current_remove_insertion_episode: null,
        current_remove_insertion_position: null,
        current_remove_content_name: null,
        hide_remove_content_modal_session: false,
        max_items_per_page: 50,
        pages: 0,
        current_page: 0,
        in_search_mode: false,
        show_error_component: false,
        remove_insertion_prompt: '',
        setup_initial_table() {
            this.pages = Math.ceil(this.episode_data.length / this.max_items_per_page);
            this.load_data_on_page();
        },
        update_page_component() {
            if (this.in_search_mode) {
                this.pages = Math.ceil(this.search_episode_data.length / this.max_items_per_page);
            } else {
                this.pages = Math.ceil(this.episode_data.length / this.max_items_per_page);
            }
        },
        load_data_on_page() {
            let that = this;
            let episode_data_to_load;
            if (that.in_search_mode) {
                episode_data_to_load = that.search_episode_data
            } else {
                episode_data_to_load = that.episode_data
            }
            const startIndex = that.current_page * that.max_items_per_page;
            let endIndex = null;
            if ((startIndex + that.max_items_per_page) >= episode_data_to_load.length) {
                endIndex = episode_data_to_load.length;
            } else {
                endIndex = startIndex + that.max_items_per_page
            }
            this.current_page_episode_data = [];

            for (let i = startIndex; i < endIndex; i++) {
                that.current_page_episode_data.push(episode_data_to_load[i])
            }
        },
        refresh_content_insertions() {
            this.content_insertions = [...this.content_insertions]
        },
        format_episode_season(episode) {
            if (episode.episode_season === 'null' && episode.episode_number === 'null') {
                return ''
            } else if (episode.episode_season === 'null' && episode.episode_number !== 'null') {
                return `E${episode.episode_number}`
            } else if (episode.episode_season !== 'null' && episode.episode_number === 'null') {
                return `S${episode.episode_season}`
            } else {
                return `S${episode.episode_season}:E${episode.episode_number}`
            }
        },
        display_pre_content(episode) {
            if (this.check_content_length(episode)) {
                let data = episode.content.filter((content) => {
                    return content.placement.toLowerCase() === 'pre'
                })

                if (data.length > 0) {
                    return data
                } else {
                    return [{id: null, content: 'No content', placement: null}]
                }

            } else {
                return [{id: null, content: 'No content', placement: null}]
            }
        },
        display_post_content(episode) {
            if (this.check_content_length(episode)) {
                let data = episode.content.filter((content) => {
                    return content.placement.toLowerCase() === 'post'
                })

                if (data.length > 0) {
                    return data
                } else {
                    return [{id: null, content: 'No content', placement: null}]
                }
            } else {
                return [{id: null, content: 'No content', placement: null}]
            }
        },
        check_content_length(episode) {
            return episode.content.length > 0
        },
        same_pre_post_roll(episode) {
            let that = this;
            if (this.check_content_length(episode)) {
                let pre_roll = that.display_pre_content(episode)[0]
                let post_roll = that.display_post_content(episode)[0]
                if (pre_roll && post_roll) {
                    return (pre_roll.content_id === post_roll.content_id) && (pre_roll.content_id !== null) && (post_roll.content_id !== null)
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        activate_remove_insertion_modal(episode_id, position, content_name, status = null) {
            let that = this;
            this.current_remove_insertion_episode = episode_id
            this.current_remove_insertion_position = position
            this.current_remove_content_name = content_name
            if (that._visible_modal()) {
                switch (status) {
                    case 'draft':
                        this.remove_insertion_prompt = `This episode has the current ${this.current_remove_insertion_position.replace(`_`, `-`)} audio drafted:`
                        break
                    default:
                        this.remove_insertion_prompt = `This episode has the current ${this.current_remove_insertion_position.replace(`_`, `-`)} audio:`
                        break
                }
                this.remove_content_modal = true
            } else {
                that.deactivate_remove_insertion_modal()
            }
        },
        _visible_modal() {
            return sessionStorage.getItem('hide_remove_content_modal_session') !== 'true'
        },
        deactivate_remove_insertion_modal() {
            this.content_insertions.push(this.current_remove_insertion_episode + ':' + this.current_remove_insertion_position + ':remove')
            let input_html = '<input type="checkbox"' +
                'x-model="content_insertions"' +
                ':value="episode.id + \':location:add\'"'.replace('location', this.current_remove_insertion_position) +
                'class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">'

            document.getElementById('ql-' + this.current_remove_insertion_episode + '-input-' + this.current_remove_insertion_position).innerHTML = input_html
            this.remove_content_modal = false
            this.current_remove_insertion_episode = null
            this.current_remove_insertion_position = null
            this.current_remove_content_name = null
        },
        _has_pre_roll_data() {
            // Check to see if episode data content has placement of pre_roll
            let that = this
            let has_pre_roll = false
            that.episode_data.forEach((episode) => {
                episode.content.forEach((content) => {
                    if (content.placement === 'pre') {
                        has_pre_roll = true
                    }
                })
            })
            return has_pre_roll
        },
        _has_post_roll_data() {
            // Check to see if episode data content has placement of pre_roll
            let that = this
            let has_post_roll = false
            that.episode_data.forEach((episode) => {
                episode.content.forEach((content) => {
                    if (content.placement === 'post') {
                        has_post_roll = true
                    }
                })
            })
            return has_post_roll
        },
        toggle_bulk_add_modal(data) {
            this.selected_pre_post_option = data
            if (data === 'pre-roll') {
                this.bulk_add_pre_post_modal = true
                if (this._has_pre_roll_data()) {
                    this.selected_pre_post_header = "Some Episodes Already Have Content!"
                    this.selected_pre_post_text = "Do you wish to replace the content in your selected episodes?"
                } else {
                    this.selected_pre_post_header = "Confirm action"
                    this.selected_pre_post_text = "This action will add/remove all the selected pre-rolls. Are you sure?"
                }
            }

            if (data === 'post-roll') {
                this.bulk_add_pre_post_modal = true
                if (this._has_post_roll_data()) {
                    this.selected_pre_post_header = "Some Episodes Already Have Content!"
                    this.selected_pre_post_text = "Some of your episodes already have " + data + " content in them. Do you wish to replace the content for all of your selected episodes? This cannot be undone once you click publish"
                } else {
                    this.selected_pre_post_header = "Confirm action"
                    this.selected_pre_post_text = "This action will add/remove all the selected post-rolls. Are you sure?"
                }
            }
        },
        bulk_add_pre_post_data() {
            // This method takes the episode id from episode_data and adds it to the content_insertions array in the format of episode_id:pre_roll:add
            // This also takes the id with the format of ql-episode_id-input-pre_roll and replaces the input with a checked checkbox
            let that = this
            let selected_pre_post = that.selected_pre_post_option.replace("-", "_")
            that.episode_data.forEach((episode) => {
                that.content_insertions.push(episode.id + ':' + selected_pre_post + ':add')
                let input_id = document.getElementById('ql-' + episode.id + '-input-' + selected_pre_post)
                if (input_id !== null) {
                    let input_html = '<input type="checkbox" ' +
                        'x-model="content_insertions" ' +
                        ':value="episode.id + \':location:add\'" '.replace('location', selected_pre_post) +
                        'class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">'

                    document.getElementById('ql-' + episode.id + '-input-' + selected_pre_post).innerHTML = input_html
                }
            })
            console.log('selected_pre_post', selected_pre_post)
            if (selected_pre_post === 'pre_roll') {
                if (that.pre_roll_all === true) {
                    that.pre_roll_all = false
                } else {
                    that.pre_roll_all = true
                }
            } else if (selected_pre_post === 'post_roll') {
                if (that.post_roll_all === true) {
                    that.post_roll_all = false
                } else {
                    that.post_roll_all = true
                }
            }
            this._clean_content_insertion_empty_values()
            this._remove_duplicate_content_insertions()
            this.bulk_add_pre_post_modal = false
        },
        _clean_content_insertion_empty_values() {
            // This method removes any empty values from the content_insertions array
            let that = this
            that.content_insertions = that.content_insertions.filter((value) => {
                return value !== ''
            })
        },
        _remove_duplicate_content_insertions() {
            // This method removes any duplicate values from the content_insertions object
            let that = this
            that.content_insertions = that.content_insertions.filter((value, index, self) => {
                return self.indexOf(value) === index
            })
        },
        validate_content_name() {
            if (this.content_name.trim().length === 0) {
                alert('Content name is invalid');
                return false;
            }
            if (this.content_type.trim().length === 0) {
                alert('Content type is invalid');
                return false;
            }
            return true;
        },
        submit_content_name() {
            if (this.validate_content_name()) {
                let that = this
                if (this.content_insertion_id) {
                    let update_url = this.$refs.new_content_insertion_data.dataset.updateDetailsUrl;
                    fetch(update_url, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        method: "PATCH",
                        body: JSON.stringify({
                            authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                            content_name: that.content_name,
                            content_type: that.content_type
                        })
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                window.location.reload()
                            }
                        })
                } else {
                    let create_url = this.$refs.new_content_insertion_data.dataset.createUrl;
                    fetch(create_url, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        method: "POST",
                        body: JSON.stringify({
                            authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                            content_insertion: {
                                content_name: that.content_name,
                                content_type: that.content_type
                            }
                        })
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                window.location.reload()
                            }
                        })
                }
            }
        },
        submit_audio_upload() {
            let update_url = this.$refs.new_content_insertion_data.dataset.updateUrl;

            console.log(update_url)
            fetch(update_url, {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "PATCH",
                body: JSON.stringify({
                    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                    data: true
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        window.location.assign(data.url)
                    }
                })
        },
        submit_content_insertions() {
            let that = this
            let submit_url = this.$refs.new_content_insertion_data.dataset.bulkUpdateUrl;
            if (submit_url === 'null') {
                console.log('submit_url returned null', submit_url)
                return false;
            }
            console.log('submit url create page', submit_url)

            fetch(submit_url, {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "PATCH",
                body: JSON.stringify({
                    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                    content_insertion: {
                        content_insertions: that.content_insertions,
                        pre_roll_all: that.pre_roll_all,
                        post_roll_all: that.post_roll_all,
                    }
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        window.location.assign(data.url)
                    }
                })
        },
        submit_content_insertions_draft() {
            let that = this
            let submit_url = this.$refs.new_content_insertion_data.dataset.bulkDraftUpdateUrl;
            console.log(submit_url)
            if (submit_url === 'null') {
                console.log('submit_url returned null', submit_url)
                return false;
            }
            console.log('submit url create page', submit_url)

            fetch(submit_url, {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "PATCH",
                body: JSON.stringify({
                    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                    content_insertion: {
                        content_insertions: that.content_insertions,
                        pre_roll_all: that.pre_roll_all,
                        post_roll_all: that.post_roll_all,
                    }
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        window.location.assign(data.url)
                    }
                })
        },
        show_errors(error_array) {
            const error_el = this.$refs.error_list
            error_el.innerHTML = ""
            error_array.forEach(e => {
                error_el.innerHTML += `<li class="text-sm">${e}</li>`
            })
            this.show_error_component = true
        },
        go_to_previous_stage() {
            let that = this
            let previousStageUrl = this.$refs.new_content_insertion_data.dataset.previousStageUrl;
            fetch(previousStageUrl, {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "PATCH",
                body: JSON.stringify({
                    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        window.location.reload()
                    }
                })
        }
    }))
})

function uppy_content_insertion(alpine) {

    let signature = document.getElementById('ql-data-points').dataset.signature
    let location = document.getElementById('ql-data-points').dataset.storage
    let template_id = document.getElementById('ql-data-points').dataset.template

    const uppy = new Uppy({
        id: 'uppy_content_insertion',
        debug: true,
        autoProceed: true,
        restrictions: {
            maxFileSize: 150 * 1024 * 1024,
            minFileSize: 1,
            maxTotalFileSize: 150 * 1024 * 1024,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: ['audio/*'],
            requiredMetaFields: [],
        },
        locale: {
            strings: {
                youCanOnlyUploadFileTypes: 'You can only upload audio files',
            },
        },
    })


    uppy.use(Dashboard, {
        trigger: document.getElementById('content-uppy-trigger'),
        closeAfterFinish: true,
        showProgressDetails: true,
        note: 'Audio files should be 150 MB or less.'
    })

    uppy.use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
            auth: {
                key: signature,
            },
            template_id: template_id
        },
        waitForEncoding: true,
        waitForMetadata: true,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        fields: {
            'location': location,
        },
    })

    uppy.on('upload-error', (file, error, response) => {
        if (error.isNetworkError) {
            // Let your users know that file upload could have failed
            // due to firewall or ISP issues
            alert("The audio file was not uploading\n\n" +
                "If you are uploading from an office network, please check with your IT department regarding firewall settings. \n\n" +
                "Error: " + error.message)
        } else {
            alert("The audio file was not uploaded\n\n" + "Please check your internet connection and try again")
        }
    })

    uppy.on('complete', (result) => {
        result.successful.forEach(file => {
            console.log(file)
            console.log(file.response)
            let audio_div = document.getElementById('ql-uploaded-audio-insert')
            audio_div.setAttribute('src', file.response.uploadURL)
            audio_div.load()
            alpine.audio_upload = 'yes'
            handleAudioDuration();
        })
        uppy.close()
        uppy_content_insertion(alpine)
    })
}

function handleAudioDuration() {
    const audio_div = document.getElementById('ql-uploaded-audio-insert')
    const time_div = document.getElementById('audio-play-time')
    time_div.innerHTML = "00:00/00:00"
    audio_div.addEventListener("timeupdate", () => {
        time_div.innerHTML = convertSeconds(audio_div.currentTime) + '/' + convertSeconds(audio_div.duration);
    })
}

function convertSeconds(seconds) {
    if (seconds >= 3600) {
        return new Date(seconds * 1000).toISOString().slice(11, 19);
    } else {
        return new Date(seconds * 1000).toISOString().slice(14, 19);
    }
}