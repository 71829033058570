document.addEventListener('alpine:init', () => {

    Alpine.data('admin_demographics_edit_x_data', () => ({
        init() {
            let loaded_data = JSON.parse(JSON.parse(this.$refs.admin_demographics_edit_xref.dataset.managementData));
            this.match_percent = parseInt(loaded_data.item.match_percent)
            this.total_monthly_credits = parseInt(loaded_data.item.total_monthly_credits)
            this.show_id = loaded_data.item.show_id
        },
        match_percent: 0,
        total_monthly_credits: 0,
        update_message: false,
        show_id: null,
        update_text: 'Update',
        submit_data() {
            if (isNaN(parseInt(this.match_percent))) {
                alert('Please enter a match percent.');
                return;
            }
            if (parseInt(this.match_percent) < 10 || parseInt(this.match_percent > 100)) {
                alert('Match percent must be between 10 and 100.');
                return;
            }
            if (isNaN(parseInt(this.total_monthly_credits))) {
                alert('Please enter a total monthly credits.');
                return;
            }

            if (parseInt(this.total_monthly_credits) < 1) {
                alert('Total monthly credits must be greater than 0.');
                return;
            }
            this.update_text = 'Updating...';
            let url = this.$refs.admin_demographics_edit_xref.dataset.updateUrl;
            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    match_percent: this.match_percent,
                    total_monthly_credits: this.total_monthly_credits,
                    show_id: this.show_id,
                    update_demographics: true
                })
            }).then((response) => {
                if (response.ok) {
                    this.update_text = 'Updated!';
                    this.update_message = true;
                    setTimeout(() => {
                        this.update_message = false;
                        this.update_text = 'Update';
                    }, 3000);
                } else {
                    this.update_text = 'Update';
                    alert('Something went wrong: - ' + response.statusText);
                }
            });
        }
    }));
});
