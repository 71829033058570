document.addEventListener('alpine:init', () => {

    Alpine.data('episode_editor_x_data', () => ({
        init() {
            let pre_load_data = this.$refs.pre_load_data
            this.content_insertions = JSON.parse(pre_load_data.dataset.contentInsertions)
                .map((content_insertion) => {
                    return {
                        id: content_insertion[0],
                        content_name: content_insertion[1],
                        content_url: content_insertion[2],
                        status: content_insertion[3]
                    }
                })


            this.pre_content_insertion_id = JSON.parse(pre_load_data.dataset.preRoll) === null ? '' : JSON.parse(pre_load_data.dataset.preRoll).id
            this.pre_content_insertion_title = JSON.parse(pre_load_data.dataset.preRoll) === null ? '' : JSON.parse(pre_load_data.dataset.preRoll).content_name
            this.pre_content_insertion_url = JSON.parse(pre_load_data.dataset.preRoll) === null ? '' : JSON.parse(pre_load_data.dataset.preRoll).content_url
            this.is_pre_roll_draft = JSON.parse(pre_load_data.dataset.preRoll) !== null && JSON.parse(pre_load_data.dataset.preRoll).status === 'draft'
            this.post_content_insertion_id = JSON.parse(pre_load_data.dataset.postRoll) === null ? '' : JSON.parse(pre_load_data.dataset.postRoll).id
            this.post_content_insertion_title = JSON.parse(pre_load_data.dataset.postRoll) === null ? '' : JSON.parse(pre_load_data.dataset.postRoll).content_name
            this.post_content_insertion_url = JSON.parse(pre_load_data.dataset.postRoll) === null ? '' : JSON.parse(pre_load_data.dataset.postRoll).content_url
            this.is_post_roll_draft = JSON.parse(pre_load_data.dataset.postRoll) !== null && JSON.parse(pre_load_data.dataset.postRoll).status === 'draft'
            this.show_draft_button = this.pre_content_insertion_id && this.post_content_insertion_id

            this.episode_audio = pre_load_data.dataset.episodeAudio

            this.$watch('pre_content_insertion_id', (new_value) => {
                if (new_value === '') {
                    this.pre_content_insertion_title = ''
                    this.pre_content_insertion_url = ''
                    if (!this.post_content_insertion_id)
                        this.show_draft_button = false
                } else {
                    this.pre_content_insertion_title = this.content_insertions.find((content_insertion) => {
                        return content_insertion.id === new_value
                    }).content_name

                    this.pre_content_insertion_url = this.content_insertions.find((content_insertion) => {
                        return content_insertion.id === new_value
                    }).content_url

                    this.is_pre_roll_draft = this.content_insertions.find((content_insertion) => {
                        return content_insertion.id === new_value
                    }).status === 'draft'
                    this.show_draft_button = true
                }

                // This is to make sure that the audio stops playing when the user selects a new pre-roll
                this.current_playing_id = ''

                // This pauses the audio completely
                this.pause_audio()

                this.$refs.pre_audio.load()
            })

            this.$watch('post_content_insertion_id', (content_insertion_id) => {
                if (content_insertion_id === '') {
                    this.post_content_insertion_title = ''
                    this.post_content_insertion_url = ''
                    if (!this.pre_content_insertion_id)
                        this.show_draft_button = false
                } else {
                    this.post_content_insertion_title = this.content_insertions.find((content_insertion) => {
                        return content_insertion.id === content_insertion_id
                    }).content_name

                    this.post_content_insertion_url = this.content_insertions.find((content_insertion) => {
                        return content_insertion.id === content_insertion_id
                    }).content_url

                    this.is_post_roll_draft = this.content_insertions.find((content_insertion) => {
                        return content_insertion.id === content_insertion_id
                    }).status === 'draft'
                    this.show_draft_button = true
                }

                // This is to make sure that the audio stops playing when the user selects a new post-roll
                this.current_playing_id = ''

                // This pauses the audio completely
                this.pause_audio()

                this.$refs.post_audio.load()
            })

            // This function determines the logic for playing the audio
            // If it is 'episode' as the content insertion id, then it plays the episode audio
            // If it is the content insertion id, it plays the corresponding content insertion
            // If it is empty, it pauses the audio
            this.$watch('current_playing_id', (content_insertion_id) => {
                if (content_insertion_id === 'episode') {
                    this.play_audio(this.episode_audio)
                } else if (content_insertion_id.length > 0) {
                    let content_url = this.content_insertions.find((content_insertion) => {
                        return content_insertion.id === content_insertion_id
                    }).content_url

                    this.$dispatch('set-pause', true)
                    this.play_audio(content_url)
                } else {
                    this.pause_audio()
                }
            })
        },
        content_insertions: [],
        episode_audio: null,
        pre_content_insertion_id: '',
        pre_content_insertion_title: '',
        pre_content_insertion_url: '',
        post_content_insertion_id: '',
        post_content_insertion_title: '',
        post_content_insertion_url: '',
        current_playing_id: '',
        remove_modal: false,
        remove_modal_heading: '',
        remove_modal_prompt: '',
        audio_position_to_remove: '',
        change_modal: false,
        change_modal_heading: '',
        change_modal_prompt: '',
        new_pre_audio_id: '',
        new_post_audio_id: '',
        audio_position_to_change: '',
        is_pre_roll_draft: false,
        is_post_roll_draft: false,
        show_draft_button: true,
        pause_audio() {
            let audio_element = this.$refs.hidden_audio_player
            audio_element.pause()
            audio_element.src = ''
        },
        play_audio(content_url) {
            let audio_element = this.$refs.hidden_audio_player
            audio_element.src = content_url
            audio_element.play()
        },
        attach_listener(listener_audio, next_audio) {
            if (listener_audio && next_audio) {
                listener_audio.addEventListener("ended", () => {
                    this.reset_and_play_audio(next_audio)
                });
            }
        },
        reset_and_play_audio(audio) {
            audio.currentTime = 0;
            audio.play();
        },
        pre_content_insertions_present() {
            return this.pre_content_insertion_id.length !== 0
        },
        post_content_insertions_present() {
            return this.post_content_insertion_id.length !== 0
        },
        show_remove_modal(title, position) {
            const episodeTitle = this.$refs.pre_load_data.dataset.episodeTitle
            this.audio_position_to_remove = position
            this.remove_modal_heading = `This will remove the ${position}-roll audio!`
            this.remove_modal_prompt = `Are you sure you want to remove ${title} as your ${position}-roll audio for ${episodeTitle}?`
            this.remove_modal = true
        },
        remove_audio() {
            if (this.audio_position_to_remove === 'pre') {
                this.pre_content_insertion_id = ''
                this.$refs.pre_roll_select.value = ''
            } else {
                this.post_content_insertion_id = ''
                this.$refs.post_roll_select.value = ''
            }
            this.remove_modal = false
        },
        handle_pre_audio_change(e) {
            e.preventDefault()
            const episodeTitle = this.$refs.pre_load_data.dataset.episodeTitle
            this.new_pre_audio_id = e.target.value
            const new_pre_audio_text = e.target.options[e.target.selectedIndex].text;

            if (this.new_pre_audio_id !== this.pre_content_insertion_id) {
                this.change_modal_heading = `This will change the current pre-roll audio!`
                this.change_modal_prompt = `Are you sure you want to set ${new_pre_audio_text} as the new pre-roll audio for ${episodeTitle}?`
                this.change_modal = true
                this.audio_position_to_change = 'pre'
            }
        },
        handle_post_audio_change(e) {
            const episodeTitle = this.$refs.pre_load_data.dataset.episodeTitle
            this.new_post_audio_id = e.target.value
            const new_post_audio_text = e.target.options[e.target.selectedIndex].text;

            if (this.new_post_audio_id !== this.post_content_insertion_id) {
                this.change_modal_heading = `This will change the current post-roll audio!`
                this.change_modal_prompt = `Are you sure you want to set ${new_post_audio_text} as the new post-roll audio for ${episodeTitle}?`
                this.change_modal = true
                this.audio_position_to_change = 'post'
            }
        },
        change_audio() {
            this.audio_position_to_change === 'pre' ? this.pre_content_insertion_id = this.new_pre_audio_id : this.post_content_insertion_id = this.new_post_audio_id
            this.change_modal = false
        },
        cancel_change_audio() {
            this.audio_position_to_change === 'pre' ? this.$refs.pre_roll_select.value = this.pre_content_insertion_id : this.$refs.post_roll_select.value = this.post_content_insertion_id
            this.change_modal = false
        },
        submit_episode_editor_form(event) {
            let url = this.$refs.pre_load_data.dataset.updateUrl
            let previous_text = event.target.innerText
            let buttonId = event.target.id

            const body = {
                authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                pre_content_insertion_id: this.pre_content_insertion_id,
                post_content_insertion_id: this.post_content_insertion_id,
                save_as_draft: false
            }

            if (buttonId === 'save_as_draft') {
                body.save_as_draft = true
                event.target.innerText = 'Saving as draft...'
            } else {
                event.target.innerText = 'Publishing...'
            }

            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then((response) => {
                return response.json()
            }).then((data) => {
                window.location.assign(data.url)
            })
        }
    }))
})