function download_csv(arr, filename) {
    const csv = arr.map(row => row.join(',')).join('\n');
    const blob = new Blob([csv], {type: 'text/csv'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function download_pdf(id, file_name) {
    return new Promise((resolve, reject) => {
        // eslint-disable-line no-unused-vars
        Promise.all([
            import('jspdf'),
            import('html2canvas')
        ]).then(([module1, module2]) => {
            // Use module1 and module2 here
            window.jsPDF = module1.default;
            window.html2canvas = module2.default;
        }).then(() => {
            const main_html_element = document.getElementById(id);
            const width = main_html_element.offsetWidth;
            const height = main_html_element.offsetHeight;
            window.html2canvas(main_html_element, {
                width: width,
                height: height,
                scale: 2,
            }).then(canvas => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0);

                let pdf = null;

                if (canvas.width > canvas.height) {
                    pdf = new jsPDF("l", "mm", [canvas.width, canvas.height]);
                } else {
                    pdf = new jsPDF('p', 'mm', [canvas.height, canvas.width]);
                }

                pdf.addImage(imgData, 'JPEG', 10, 10, canvas.width, canvas.height);
                resolve();
                pdf.save(file_name);
            });
        }).catch((error) => {
            reject();
            console.error('Failed to load modules', error);
        });
    });
}

export {download_pdf};
export {download_csv};