//------------------------UPPY------------------------------------------------
import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).
import 'md-gum-polyfill'

import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'

const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload')
require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')


document.addEventListener('turbo:load', () => {
    document.querySelectorAll('[data-uppy]').forEach(element => setupUppy(element))
})

function setupUppy(element) {
    let trigger = element.querySelector('[data-behavior="uppy-trigger"]')
    let form = element.closest('form')
    let field_name = element.dataset.uppy
    let direct_upload_url = document.querySelector("meta[name=direct-upload-url]").getAttribute("content")

    trigger.addEventListener("click", (event) => event.preventDefault())

    const uppy = new Uppy({
        id: 'uppy',
        autoProceed: true,
        allowMultipleUploads: false,
        restrictions: {
            maxFileSize: 300 * 1024 * 1024,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: ['.mp3']
        }
    })

    uppy.use(ActiveStorageUpload, {
        directUploadUrl: direct_upload_url
    })

    uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: true,
        showProgressDetails: true,
        note: 'Audio files ending with .mp3 only. File should be 300 MB or less.'
    })

    uppy.on('complete', (result) => {
        element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

        result.successful.forEach(file => {
            appendUploadedFile(element, file, field_name)
        })
        form.submit()
    })
}

function appendUploadedFile(element, file, field_name) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', field_name)
    hiddenField.setAttribute('data-pending-upload', true)
    hiddenField.setAttribute('value', file.response.signed_id)

    element.appendChild(hiddenField)
}

//------------------------UPPY------------------------------------------------
