document.addEventListener('turbo:load', function(){
    if (document.querySelector("#dashboard-chart-download")) {
        if (document.querySelector("#dashboard-consumption-half")) {
            const consumptionRate = Math.round(document.querySelector("#dashboard-consumption-half").dataset.rate);

            var options = {
                series: [consumptionRate],
                chart: {
                    height: 250,
                    type: 'radialBar',
                    offsetY: -13,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -2,
                                fontSize: '22px'
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: -10
                    }
                },
                fill: {
                    colors: ["#5EBAB4"],
                    type: 'solid'
                },
                labels: ['Average Results'],
            };




            var consumptionChart = new ApexCharts(document.querySelector("#dashboard-consumption-half"), options);
            consumptionChart.render();    
        } else if (document.querySelector("#dashboard-consumption-full")) {
            const consumptionRate = Math.round(document.querySelector("#dashboard-consumption-full").dataset.rate);

            var options = {
                series: [consumptionRate],
                colors: ["#93D2D6"],
                chart: {
                    height: 350,
                    type: 'radialBar',
                    fontFamily: "manrope"
                },
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#888',
                                fontSize: '30px'
                            },
                            value: {
                                formatter: function(val) {
                                    return `${parseInt(val)}%`;
                                },
                                color: '#111',
                                fontSize: '36px',
                                show: true,
                                offsetY: -2,
                            }
                        },
                        hollow: {
                            size: '70%',
                        }
                    },
                },

                labels: [''],
            };





            var consumptionChart = new ApexCharts(document.querySelector("#dashboard-consumption-full"), options);
            consumptionChart.render();
        }
        fetch(`/analytics/charts?chart_type=downloads&date_range=1m`)
        .then(res => res.json())
        .then(data => {
            let options = {
                colors: ["#391599"],
                fill: {
                    type: "solid",
                    colors: ["#CDC1ED"],
                },
                dataLabels: {
                    enabled: false
                },
                chart: {
                    height: 380,
                    width: "100%",
                    type: "area",
                    id: "downloadChartID",
                    fontFamily: "manrope"
                },
                series: [
                {
                    name: "Downloads",
                    data: data
                }
                ],
                xaxis: {
                    type: 'datetime'
                }
            };

            let downloadsChart = new ApexCharts(document.querySelector("#dashboard-chart-download"), options);

            downloadsChart.render();
            updateTotal(data, "downloads");
        });

        // Range logic
        const rangeButtons = document.querySelectorAll(".rangeButton");
        rangeButtons.forEach(element => {
            element.addEventListener("click", function() {
                const chartID = this.dataset.chartid;
                const range = this.dataset.range;
                const chartType = this.dataset.chartType;
                fetch(`/analytics/charts?chart_type=${chartType}&date_range=${range}`)
                .then(res => res.json())
                .then(data => {
                    ApexCharts.exec(chartID, "updateSeries", [{data: data}], true);
                    updateTotal(data, chartType);
                })
            })
        })

        // Update 'Total' number
        function updateTotal(data, elementID) {
            let newTotal = 0
            for (let i = 0; i < data.length; i++) {
                newTotal += data[i][1]
            }
            document.querySelector(`#${elementID}-total`).textContent = newTotal;
        }

        // Button styling 
        const downloadButtons = document.querySelectorAll('[data-chart-type="downloads"]');
        downloadButtons.forEach(element => {
            element.addEventListener("click", function() {
                downloadButtons.forEach(element => {
                    element.classList.remove("bg-quill-activenav")
                    element.classList.add("bg-white")
                })
                this.classList.remove("bg-whit")
                this.classList.add("bg-quill-activenav")
            })
        })
    }
});