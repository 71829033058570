document.addEventListener('alpine:init', () => {
    Alpine.data('website_x_data', () => ({
        init() {
            console.log("Hello from website_index_page.js")
        },
        website_button_text: 'Create a CoHost website',
        podpage_modal_button_text: 'Proceed',
        no_episodes_modal: false,
        podpage_modal: false,
        create_cohost_website() {
            this.website_button_text = 'Creating your website ...'
            let website_url = this.$refs.website.dataset.websiteUrl
            fetch(website_url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                })
            }).then(function (res) {
                window.location.reload()
            })
        },
        create_podpage_website() {
            this.podpage_modal_button_text = 'Redirecting ...'
            let podpage_url = this.$refs.website.dataset.podpageUrl
            let create_podpage_website = this.$refs.website.dataset.podpageWebsiteCreateUrl
            console.log(create_podpage_website)
            fetch(create_podpage_website, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                })
            }).then(function (res) {
                window.location.replace(podpage_url)
            }).catch(function (err) {
                this.podpage_modal_button_text = 'Proceed'
                alert("There was an error with your request. Please try again.")
            })
        }
    }))
})