import flatpickr from "flatpickr";

require("flatpickr/dist/flatpickr.min.css")
let today = new Date();
let one_minute_from_now = new Date(today.getTime() + 60 * 1000); //2 = 2 hours previously it was 2 * 60 * 60 * 1000 but removed 2 because of client reason
document.addEventListener("turbo:load", () => {
    flatpickr("[data-behavior='flatpickr']", {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        minDate: one_minute_from_now,
        weekNumbers: true
    })
})