document.addEventListener("turbo:load", function () {
    const stripe = Stripe(document.querySelector("meta[name='stripe_key']").getAttribute("content"));
    const elements = stripe.elements();
    let style = {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    };
    const card = elements.create("card", {style: style});
    window.ql_custom_card = card;
    window.ql_custom_stripe_elements = elements;
    // Stripe injects an iframe into the DOM
    let card_elememt = document.getElementById("card-element");
    if (card_elememt) {
        card.mount("#card-element");
        card.on("change", function (event) {
            // Disable the Pay button if there are no card details in the Element
            document.querySelector("button").disabled = event.empty;
            if (event.error) {
                document.querySelector("#card-error").classList.remove("hidden");
                document.querySelector("#card-error-text").textContent = event.error.message;
            } else {
                document.querySelector("#card-error").classList.add("hidden");
            }
        });
        const save_card_form = document.getElementById("save_card_form");
        const clientSecret = save_card_form.dataset.setupIntentId
        const submit_btn = document.querySelectorAll('.ql-submit-btn')[0]

        submit_btn.addEventListener("click", function setup_intent_form(event) {
            event.preventDefault();

            const previous_value_on_submit_btn = submit_btn.value

            submit_btn.value = 'Processing...'

            let cardName = document.querySelector("#card_name");

            let data = {
                payment_method_data: {
                    card: card,
                    billing_details: {
                        name: cardName.value
                    }
                }
            }
            stripe.confirmCardSetup(clientSecret, {
                payment_method: data.payment_method_data
            }).then((result) => {
                if (result.error) {
                    document.querySelector("#card-error").classList.remove("hidden");
                    document.getElementById("card-error-text").textContent = result.error.message
                    submit_btn.value = previous_value_on_submit_btn;
                } else {
                    addHiddenField(save_card_form, "payment_method_id", result.setupIntent.payment_method)
                    submit_btn.value = 'Redirecting...'
                    save_card_form.submit();
                    //HTMLFormElement.prototype.submit.call(save_card_form)
                    //console.log("Form submitted")

                }
            })
        })
    }
});

function addHiddenField(form, name, value) {
    let input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", name)
    input.setAttribute("value", value)
    form.appendChild(input)
}