document.addEventListener('alpine:init', () => {

    Alpine.data('admin_demographics_index_x_data', () => ({
        demographics_modal_visibility: false,
        selected_show_id: null,
        selected_show_title: null,
        match_percent: 0,
        total_monthly_credits: 0,
        activate_demographics_button: 'Activate',
        selected_match_percent: null,
        open_demographics_modal(show_id, show_title) {
            this.selected_show_id = show_id;
            this.selected_show_title = show_title.replace(/'/g, "");
            ;
            this.demographics_modal_visibility = true;
        },
        enable_demographics() {
            if (isNaN(parseInt(this.match_percent))) {
                alert('Please enter a match percent.');
                return;
            }
            if (parseInt(this.match_percent) < 10 || parseInt(this.match_percent > 100)) {
                alert('Match percent must be between 10 and 100.');
                return;
            }
            if (isNaN(parseInt(this.total_monthly_credits))) {
                alert('Please enter a total monthly credits.');
                return;
            }

            if (parseInt(this.total_monthly_credits) < 1) {
                alert('Total monthly credits must be greater than 0.');
                return;
            }
            this.activate_demographics_button = 'Activating...';
            let url = this.$refs.demographics_admin_modal.dataset.updateUrl;
            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    show_id: this.selected_show_id,
                    match_percent: this.match_percent,
                    total_monthly_credits: this.total_monthly_credits
                })
            }).then((response) => {
                if (response.ok) {
                    this.activate_demographics_button = 'Activated!';
                    window.location.assign(this.$refs.demographics_admin_modal.dataset.indexUrl);
                } else {
                    this.activate_demographics_button = 'Activate';
                    alert('Something went wrong: - ' + response.statusText);
                }
            });
        },
        disable_demographics(show_id, show_title) {
            let confirmation = confirm('Are you sure you want to disable demographics for ' + show_title + '?\n This will remove the show from collecting demographics data but will keep the existing data.');
            if (confirmation) {
                let url = this.$refs.demographics_admin_modal.dataset.updateUrl;
                fetch(url, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                    },
                    body: JSON.stringify({
                        show_id: show_id,
                        disable: true
                    })
                }).then((response) => {
                    if (response.ok) {
                        window.location.assign(this.$refs.demographics_admin_modal.dataset.indexUrl);
                    } else {
                        alert('Something went wrong: - ' + response.statusText);
                    }
                });
            }
        }
    }));
});
