import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).
import 'md-gum-polyfill'

import Uppy from '@uppy/core'
import Transloadit from '@uppy/transloadit'
import Dashboard from '@uppy/dashboard'

require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')

document.addEventListener('alpine:init', () => {

    Alpine.data('new_episode_x_data', () => ({
        init(){
            uppy_episode_audio(this)
            uppy_cover_art(this)
            externalJS(this)
        },
        audio_upload: false,
        cover_art_upload: false,
        cover_art_error_msg: "",
        audio_file_name: "",
        save_text: "",
        audio_error: "",
        title_error: "",
        season_error: "",
        episode_number_error: "",
        episode_errors: [],
        schedule_error: "",
        draft_text: "Save as Draft",
        background_upload_text: "",
        keyword_exists: false,
        contributor_exists: false,
        audio_assembly_id: "",
        cover_art_assembly_id: "",
        show_hide_from_feed_modal: false,
        show_appear_on_rss_feed_modal: false,
        handle_hide_from_rss_feed_checkbox_clicked(target_element) {
            target_element.preventDefault()
            if (target_element.target.checked) {
                this.show_hide_from_feed_modal = true
            } else {
                this.show_appear_on_rss_feed_modal = true
            }
        },
        handle_hide_from_rss_feed_confirm() {
            document.querySelector("#app_episode_hide_from_feed").checked = true
            this.show_hide_from_feed_modal = false
        },
        handle_appear_on_rss_feed_confirm() {
            document.querySelector("#app_episode_hide_from_feed").checked = false
            this.show_appear_on_rss_feed_modal = false
        },
        has_valid_inputs(type) {
            if (type == "") { return false }

            const titleField = document.querySelector("#app_episode_title")
            const audioField = document.querySelector("#audio_assembly_id")
            const seasonField = document.querySelector("#app_episode_episode_season")
            const episodeField = document.querySelector("#app_episode_episode_number")
            const scheduleField = document.querySelector("#app_episode_scheduled_at_time")
            const scheduleLaterField = document.querySelector("#ql-schedule-later")

            let found_error = false

            if (type == "draft") {
                if (titleField.value == "") {
                    this.title_error = "Please enter a title"
                    titleField.scrollIntoView({behavior: "smooth", block: "center"})
                    found_error = true
                }
            } else if (type == "submit") {
                if (titleField.value == "") {
                    this.title_error = "Please enter a title"
                    found_error = true
                    titleField.scrollIntoView({behavior: "smooth", block: "center"})
                } else if (audioField.value == "") {
                    this.audio_error = "Please upload an audio file"
                    found_error = true
                    document.querySelector("#audio_section").scrollIntoView({behavior: "smooth", block: "center"})
                } else if (scheduleLaterField.checked && scheduleField.value == "") {
                    this.schedule_error = "Please select a date to schedule your episode."
                    scheduleField.scrollIntoView({behavior: "smooth", block: "center"})
                    found_error = true
                }

            }

            if (found_error) {
                return false
            } else {
                return true
            }
        },

        handle_submit() {
            const that = this;
            const form = document.querySelector("#new_app_episode")
            const data = new FormData(form);
            const submitData = new URLSearchParams(data);
            if (this.has_valid_inputs("submit")) {
                if (submitData.get("app_episode[publish_now]") === "true") {
                    this.save_text = "Publishing Episode...";
                } else {
                    this.save_text = "Scheduling Episode...";
                }
                submitData.append("app_episode[audio_file_name]", that.audio_file_name);
                (async () => {
                    const rawResponse = await fetch(form.action, {
                        method: 'POST',
                        body: submitData
                    });
                    const response = await rawResponse.json();

                    if (response.status === "success"){
                        setTimeout(function(){document.location.href = response.link;},250);
                    } else if (response.status === "error") {
                        that.episode_errors = response.reason
                        if (submitData.get("app_episode[publish_now]") === "true") {
                            that.save_text = "Publish";
                        } else {
                            that.save_text = "Schedule";
                        }
                        that.$refs.episode_errors.scrollIntoView({behavior: "smooth", block: "center"})
                    }
                })();
            } else {
                document.querySelector(".ql-submit").classList.add("border-red-400")
            }
        },
        handle_draft() {
            const that = this
            const form = document.querySelector("#new_app_episode")
            const data = new FormData(form);
            const submitData = new URLSearchParams(data);

            if (this.has_valid_inputs("draft")) {
                submitData.append("app_episode[is_draft]", "true");
                submitData.append("app_episode[audio_file_name]", that.audio_file_name);
                this.draft_text = "Saving...";
                (async () => {
                    const rawResponse = await fetch(form.action, {
                        method: 'POST',
                        body: submitData
                    });
                    const response = await rawResponse.json();

                    if (response.status === "success"){
                        setTimeout(function(){document.location.href = response.link;},250);
                    } else if (response.status === "error") {
                        that.episode_errors = response.reason
                        this.draft_text = "Save as Draft";
                        that.$refs.episode_errors.scrollIntoView({behavior: "smooth", block: "center"})
                    }
                })();
            } else {
                document.querySelector(".ql-submit-draft").classList.add("border-red-400")
            }
        },
        check_if_audio_uploaded() {
            let that = this;
            // Audio has been properly uploaded, do nothing
            if (this.audio_upload === true) {
                return
            }

            (async () => {
                const rawResponse = await fetch(`https://api2.transloadit.com/assemblies/${that.audio_assembly_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const content = await rawResponse.json();

                if (content.ok === "ASSEMBLY_UPLOADING" || content.ok === "ASSEMBLY_EXECUTING") {
                    that.send_upload_error_notification(that.audio_assembly_id)
                }

            })();
        },
        check_if_cover_art_uploaded() {
            let that = this;
            // Audio has been properly uploaded, do nothing
            if (this.cover_art_upload === true) {
                return
            }

            (async () => {
                const rawResponse = await fetch(`https://api2.transloadit.com/assemblies/${that.cover_art_assembly_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const content = await rawResponse.json();
                console.log(content.ok)
                if (content.ok === "ASSEMBLY_UPLOADING" || content.ok === "ASSEMBLY_EXECUTING") {
                    that.send_upload_error_notification(that.cover_art_assembly_id)
                }

            })();
        },
        send_upload_error_notification(assembly_id) {
            (async () => {
                const rawResponse = await fetch(document.getElementById('ql-data-points').dataset.uploadErrorNotifyUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                        assembly_id: assembly_id
                    })
                });
                const content = await rawResponse.json();
                console.log(content)
            })();
        }
    }))
})

function uppy_cover_art(alpine) {
    let signature = document.getElementById('ql-data-points').dataset.signature
    let template_episode_cover_art_id = document.getElementById('ql-data-points').dataset.templateEpisodeCoverArt

    const uppy = new Uppy({
        id: 'uppy_cover_art',
        debug: true,
        autoProceed: true,
        restrictions: {
            maxFileSize: 2 * 1024 * 1024,
            minFileSize: 1,
            maxTotalFileSize: 2 * 1024 * 1024,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: ['image/*'],
            requiredMetaFields: [],
        },
        locale: {
            strings: {
                youCanOnlyUploadFileTypes: 'You can only upload image files',
            },
        },
    })


    uppy.use(Dashboard, {
        trigger: document.getElementById('episode-cover-art-uppy-trigger'),
        showProgressDetails: true,
        note: 'Image must be a square and 3000px by 3000px.'
    })

    uppy.use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
            auth: {
                key: signature,
            },
            template_id: template_episode_cover_art_id
        },
        waitForEncoding: true,
        waitForMetadata: true,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
    })

    uppy.on('upload-error', (file, error, response) => {
        if (error.isNetworkError) {
            alert("The audio file was not uploading\n\n" +
                "If you are uploading from an office network, please check with your IT department regarding firewall settings. \n\n" +
                "Error: " + error.message)
        } else {
            alpine.cover_art_error_msg = error.toString()
        }
    })

    uppy.on('transloadit:assembly-created', (assembly, fileIDs) => {
        alpine.cover_art_assembly_id = assembly.assembly_id
    });

    uppy.on('transloadit:assembly-executing', (assembly) => {
        setTimeout(function() { alpine.check_if_cover_art_uploaded(); }, 60000);
    });

    uppy.on('complete', (result) => {
        result.successful.forEach(file => {
            console.log(file)
            console.log(file.response)
            document.querySelector("#new_cover_art").src = file.response.uploadURL
            document.querySelector("#episode_cover_art_assembly_id").value = file.transloadit.assembly
            alpine.cover_art_upload = true
        })
        uppy.cancelAll()
        uppy.getPlugin('Dashboard').closeModal()
    })
}

function uppy_episode_audio(alpine) {
    let signature = document.getElementById('ql-data-points').dataset.signature
    let template_id = document.getElementById('ql-data-points').dataset.template

    const uppy = new Uppy({
        id: 'uppy_episode_audio',
        debug: true,
        autoProceed: true,
        restrictions: {
            maxFileSize: 300 * 1024 * 1024,
            minFileSize: 1 * 1024 * 1024,
            maxTotalFileSize: 300 * 1024 * 1024,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes: [".MP3", ".WAV", ".AAC", ".FLAC", ".OGG"],
            requiredMetaFields: [],
        },
        locale: {
            strings: {
                youCanOnlyUploadFileTypes: 'You can only upload audio files',
            },
        },
    })


    uppy.use(Dashboard, {
        trigger: document.getElementById('content-uppy-trigger'),
        closeAfterFinish: true,
        showProgressDetails: true,
        note: 'Audio files should be 150 MB or less.'
    })

    uppy.use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
            auth: {
                key: signature,
            },
            template_id: template_id
        },
        waitForEncoding: true,
        waitForMetadata: true,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
    })

    uppy.on('upload', (data) => {
        alpine.background_upload_text = "Your audio is continuing to upload. To cancel, select Upload and click the X in the bottom right corner."
    })

    uppy.on('transloadit:assembly-created', (assembly, fileIDs) => {
        alpine.audio_assembly_id = assembly.assembly_id
    });

    uppy.on('transloadit:assembly-executing', (assembly) => {
        setTimeout(function() { alpine.check_if_audio_uploaded(); }, 180000);
    });

    uppy.on('upload-error', (file, error, response) => {
        if (error.isNetworkError) {
            alert("The audio file was not uploading\n\n" +
                "If you are uploading from an office network, please check with your IT department regarding firewall settings. \n\n" +
                "Error: " + error.message)
        } else {
            // alert("The audio file was not uploaded\n\n" + "Please check your internet connection and try again")
        }
        console.log("error", error.toString())
        console.log("response", response)
    })

    uppy.on('complete', (result) => {
        result.successful.forEach(file => {
            console.log(file)
            console.log(file.response)
            let audio_div = document.getElementById('ql-uploaded-audio-insert')
            audio_div.setAttribute('src', file.response.uploadURL)
            document.querySelector("#audio_assembly_id").value = file.transloadit.assembly
            audio_div.load()
            handleAudioDuration();
            alpine.audio_file_name = file.name
            alpine.background_upload_text = ""
            alpine.audio_upload = true
        })
        uppy.cancelAll()
        uppy.getPlugin('Dashboard').closeModal()
    })
}

function handleAudioDuration() {
    const audio_div = document.getElementById('ql-uploaded-audio-insert')
    const time_div = document.getElementById('audio-play-time')
    audio_div.addEventListener("loadedmetadata", () => {
        console.log(audio_div.duration)
        time_div.innerHTML = "00:00/" + convertSeconds(audio_div.duration)
    })
    audio_div.addEventListener("timeupdate", () => {
        time_div.innerHTML = convertSeconds(audio_div.currentTime) + '/' + convertSeconds(audio_div.duration);
    })
}

function convertSeconds(seconds) {
    if (seconds >= 3600) {
        return new Date(seconds * 1000).toISOString().slice(11, 19);
    } else {
        return new Date(seconds * 1000).toISOString().slice(14, 19);
    }
}

function loadScript(url, callback) {
    let script = document.createElement("script")
    script.type = "text/javascript";

    if (script.readyState) {  //IE
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" ||
                script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {  //Others
        script.onload = function () {
            callback();
        };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}

function externalJS(alpine) {
    loadScript("https://cdn.jsdelivr.net/npm/tom-select@1.7.6/dist/js/tom-select.complete.min.js", function () {
        new TomSelect("#app_episode_keywords", {
            plugins: {
                remove_button: {
                    title: 'Remove this tag',
                }
            },
            persist: false,
            create: true,
            createOnBlur: true,
            render: {
                no_results: null
            },
            createFilter: function(input) {
                const exists = (input in this.options);
                if (exists) {
                    alpine.keyword_exists = true
                } else {
                    alpine.keyword_exists = false
                }
                return !exists
            },
            onBlur: function() {
                alpine.keyword_exists = false
            }
        });

        new TomSelect("#app_episode_contributors", {
            plugins: {
                remove_button: {
                    title: 'Remove this tag',
                }
            },
            persist: false,
            create: true,
            createOnBlur: true,
            render: {
                no_results: null
            },
            createFilter: function(input) {
                const exists = (input in this.options);
                if (exists) {
                    alpine.contributor_exists = true
                } else {
                    alpine.contributor_exists = false
                }
                return !exists
            },
            onBlur: function() {
                alpine.contributor_exists = false
            }
        })
    })

    const now = new Date();
    const oneHourLater = new Date(now.getTime() + (60 * 60 * 1000));
    const scheduleDate = flatpickr("#app_episode_scheduled_at_time", {enableTime: true, minDate: Date.now(), defaultDate: oneHourLater});

    const scheduleSection = document.querySelector("#ql-schedule-section")

    document.querySelector("#ql-schedule-later").addEventListener("click", function () {
        scheduleSection.classList.remove("hidden")
    })

    document.querySelector("#ql-schedule-now").addEventListener("click", function () {
        scheduleSection.classList.add("hidden")
    })

    let toolbar_options = [
        ['bold', 'italic'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['link'],
        ['undo'],
        ['redo'],
        [{'direction': 'rtl'}],
        ['clean']
    ];

    let icons = Quill.import('ui/icons');
    icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
    icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

    Quill.register('modules/counter', function (quill, options) {
        let container = document.querySelector(options.container);
        quill.on('text-change', function () {
            let text = quill.getText();
            let words = text.split(/\s+/).length - 1;
            let characters = text.length - 1;

            if (characters > 4000) {
                container.innerHTML = `<span class="text-red-400 font-bold">Reached limit of ${characters} characters for Apple description cutoff</span>`;
            } else if (characters === 0) {
                container.innerHTML = "";
            } else {
                container.innerHTML = `${words} words, ${characters} characters`;
            }
        });
    });
// Initialize Quill editor
    let quill = new Quill('.ql-editor', {
        theme: 'snow',
        placeholder: 'Your episode description...',
        modules: {
            toolbar: {
                container: toolbar_options,
                handlers: {
                    undo: function (value) {
                        this.quill.history.undo();
                    },
                    redo: function (value) {
                        this.quill.history.redo();
                    },
                    'link': function(value) {
                        if (value) {
                            var href = prompt('Enter the URL', "https://");
                            this.quill.format('link', href);
                        } else {
                            this.quill.format('link', false);
                        }
                    }
                }
            },
            history: {
                delay: 2000,
                maxStack: 500,
                userOnly: true
            },
            counter: {
                container: '#counter'
            }
        }
    });





    let description = document.getElementById('app_episode_description');
    quill.root.innerHTML = description.value;

    quill.on('editor-change', function () {
        description.value = quill.root.innerHTML;
    });

    document.addEventListener('turbo:load load', function () {
        let description = document.getElementById('app_episode_description');
        console.log("description", description.value);
        quill.root.innerHTML = description.value;
    });
}