document.addEventListener('alpine:init', () => {

    Alpine.data('content_insertions_x_data', () => ({
        prevClickedMenu: undefined,
        subMenuOpen: false,
        clickedOutside: 0,
        open_sub_menu(event) {
            this.subMenuOpen = true
            this.clickedOutside = 0;
            const menu = event.currentTarget.nextElementSibling
            if (this.prevClickedMenu && this.prevClickedMenu !== menu) {
                this.prevClickedMenu.classList.add("hidden")
            }
            this.prevClickedMenu = menu
            if (menu.classList.contains("hidden")) {
                menu.classList.remove("hidden")
            } else {
                menu.classList.add("hidden")
                this.subMenuOpen = false
            }
        },
        close_sub_menu() {
            if (this.subMenuOpen && this.clickedOutside === 2) {
                this.prevClickedMenu.classList.add("hidden")
                this.subMenuOpen = false
            }
        },
        set_to_draft(event) {
            let save_as_draft_url = event.currentTarget.dataset.updateUrl

            let conf = confirm('Are you sure you want to set this content insertion as a draft? \n\nThis will remove all the content locations you have added below and remove the content from episodes if any.');

            if (conf) {
                fetch(save_as_draft_url, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: "PATCH",
                    body: JSON.stringify({
                        authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                        content_insertion: {
                            save_as_draft: true,
                            content_insertions: []
                        }
                    })
                })
                    .then(response => response.json())
                    .then(data => {
                        window.location.reload()
                    })
            }
        },
        delete_content_insertion(event) {
            let delete_url = event.currentTarget.dataset.deleteUrl;

            let conf = confirm('Are you sure you want to delete the content insertion? \n\nThis will remove all the audio snippets and delete the content insertion permanently.');

            if (conf) {
                fetch(delete_url, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: "DELETE",
                    body: JSON.stringify({
                        authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                        content_insertion: {
                            main_page: true
                        }
                    })
                })
                    .then(response => response.json())
                    .then(data => {
                        window.location.reload()
                    })
            }
        }
    }));
})