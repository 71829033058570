import uploadcare from 'uploadcare-widget'


document.addEventListener('turbo:load', () => {

    let uploadcare_widget = document.getElementById('cover_art_uploader')
    if (!uploadcare_widget) {
        return false
    }


    const widget = uploadcare.Widget("#cover_art_uploader",
        {
            publicKey: '20fef215a65cd7bcb3df',
            imagesOnly: true,
            debugUploads: true
        });

    function maxDimensions(width, height) {
        return function (fileInfo) {
            let imageInfo = fileInfo.originalImageInfo;
            if (imageInfo !== null) {
                if (imageInfo.width > width || imageInfo.height > height) {
                    throw new Error('max_dimensions');
                }
            }
        };
    }

    function minDimensions(width, height) {
        return function (fileInfo) {
            let imageInfo = fileInfo.originalImageInfo;
            if (imageInfo !== null) {
                if (imageInfo.width < width || imageInfo.height < height) {
                    throw new Error('min_dimensions');
                }
            }
        };
    }

    function not_square_image(fileInfo) {
        let imageInfo = fileInfo.originalImageInfo;
        if (imageInfo !== null) {
            if (imageInfo.width !== imageInfo.height) {
                throw new Error('not_square_image');
            }
        }
    }

    function max_file_size(fileInfo) {
        if (fileInfo.size > 1024 * 1014 * 2) {
            throw new Error('max_file_size');
        }
    }


    widget.onUploadComplete(fileInfo => {
        let cover_art = document.createElement('img')
        cover_art.src = fileInfo.cdnUrl
        cover_art.className = 'w-80 h-80'
        document.getElementById('qlj-cover-art-image-result').innerHTML = ''
        document.getElementById('qlj-cover-art-image-result').appendChild(cover_art)
    });

    widget.validators.push(maxDimensions(3000, 3000))
    widget.validators.push(minDimensions(1500, 1500))
    widget.validators.push(max_file_size)
    widget.validators.push(not_square_image)
})